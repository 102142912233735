import React from 'react';

export default function ViewPoliticaDePrivacidadeESeguranca() {
   return(
       <>
        Política de Privacidade e Segurança<br/><br/>

A Política de Privacidade e Segurança abaixo constitui parte integrante dos Termos e Condições de Uso.<br/><br/>

1. Identificação<br/>
Este site é de propriedade, mantido, e operado por CONDUTOR LEGAL PRESTADORA DE SERVICOS LTDA, CNPJ 35.447.156/0001-08, endereço Av. das Américas, 3434 Bloco 4, 3º andar sala 305, CEP: 22.640-102, Rio de Janeiro, RJ.<br/><br/>
2. Contato – CENTRAL DE ATENDIMENTO<br/>
Caso o Usuário necessite de qualquer informação, esclarecimento ou atendimento com relação a esta Política de Pagamentos, a Condutor Legal disponibiliza a CENTRAL DE ATENDIMENTO para receber todas as comunicações que o Usuário desejar fazer.<br/>
A CENTRAL DE ATENDIMENTO opera por meios dos canais de comunicação listados abaixo:<br/>
(a) Eletronicamente, através do e-mail atendimento@condutorlegal.com, durante os horários de funcionamento<br/><br/>
3. Informações gerais<br/>
A Condutor Legal toma todas as medidas necessárias para proteger a privacidade do Usuário, em atendimento à legislação em vigor. Este documento detalha as formas de coleta, guarda, utilização, compartilhamento e divulgação de seus dados pessoais, bem como aponta claramente as medidas tomadas para assegurar a proteção dos dados coletados.<br/><br/>
4. Coleta, utilização e guarda dos dados<br/>
Conta de Acesso<br/>
Para a criação de uma Conta de Acesso no Site, o Usuário deve preencher um cadastro, que pode variar de complexidade de acordo com as operações que pretende fazer, desde a manifestação / interesse em participar de um evento futuro veiculado no site, na seção “Quero Invadir” ou o cadastro completo para aquisição de produto via ecommerce.
Desta forma, um cadastro simples composto de endereço de e-mail será requerido para o envio de mensagens publicitárias e promocionais ao Usuário sobre produtos, serviços, promoções e/ou novidades da Condutor Legal.<br/>
Já para a realização de compras, o Usuário deverá criar uma Conta de Acesso completa, fornecendo nome completo, endereço de e-mail, número de telefone, CPF, endereço completo (estado, cidade, bairro, rua, número, complemento, informações extras).<br/>

Os dados fornecidos pelos Usuários serão armazenados e utilizados pela Condutor Legal, por si ou por terceiros por ela contratados, para fornecer os produtos do Site, realizar as transações comerciais, processar pagamentos a elas relacionados, compartilhar dados, enviar e-mails de ofertas, produtos, serviços e campanhas de marketing, armazenar o histórico de produtos visualizados, promover campanhas de retargeting, personalizar páginas do Site, verificar dados bancários para evitar fraudes e realizar entregas e devolução.<br/>

Os dados poderão ser compartilhados com terceiros contratados pela Condutor Legal nos termos desta Política com a finalidade de concretizar as compras, ressaltando-se que a criação de cada Conta de Acesso ou qualquer cadastro no Site, para o qual seja necessário o envio de qualquer dado pessoal do Usuário, pressupõe o consentimento expresso quanto coleta/uso/armazenamento/tratamento dos dados (inclusive pelos terceiros contratados), podendo, inclusive, ser individualizados para fins estatísticos.<br/>
O Usuário é responsável, nas esferas civil e criminal, pela veracidade e atualização dos dados fornecidos (inclusive os dados pessoais) e a Condutor Legal se exime de qualquer reponsabilidade por danos decorrentes do reenchimento incompleto, impreciso ou inexato do cadastro pelo Usuário, sob qualquer meio ou forma, ou, ainda, pelo uso desse cadastro de forma indevida por qualquer terceiro não autorizado a usar tais dados ou, ainda, por terceiros que tenham, devida ou  indevidamente, obtido os dados do Usuário para acesso no Site, agindo como se ele fosse.<br/><br/>

Cookies<br/>
Visando oferecer a melhor experiência de navegação e Compras ao Usuário, a Condutor Legal utiliza-se de tecnologias para coletar e armazenar informações relacionadas à visita do Usuário no Site e isso pode incluir o envio de um ou mais cookies ou identificadores anônimos que coletam dados relativos às preferências de navegação e às páginas visitadas pelo Usuário.<br/>
Desta forma, a apresentação do Site fica personalizada e alinhada aos interesses pessoais do Usuário. A utilização destes dados fica restrita ao objetivo indicado e a Condutor Legal se compromete a não utilizar ou permitir a utilização de tais dados com outra finalidade. Ademais, a coleta, guarda e tratamento destes dados é absolutamente automatizada, não havendo nenhuma possibilidade de contato humano com os dados em questão.<br/>
O Usuário pode e poderá, a qualquer tempo, caso discorde da política de cookies acima, utilizar as ferramentas de seu navegador que impedem a instalação de cookies e ainda apagar quaisquer cookies existentes em seu dispositivo de conexão com a internet. Neste caso, algumas funcionalidades do Site poderão apresentar erros. A Condutor Legal poderá ainda utilizar-se de outras tecnologias para a coleta de dados de navegação dos Usuários, comprometendo-se a guardá-los, tratá-los e utilizá-los em conformidade com este Política.<br/><br/>

Registros de acesso<br/>
A Condutor Legal manterá em sua base de dados todas as informações relativas aos acessos do Usuário ao Site, incluindo, mas não se limitando ao endereço IP, às páginas acessadas, aos horários e datas de acesso, e o dispositivo de acesso utilizado, nos termos da legislação vigente. Tais registros poderão ser utilizados em investigações internas ou públicas para averiguação de práticas que possam gerar quaisquer prejuízos à Condutor Legal, inclusive a prática de crimes em ambientes virtuais.<br/><br/>

Outras formas<br/>
A Condutor Legal poderá coletar dados pessoais do Usuário, que não os identificados aqui, que sejam inseridos pelo Usuário e sobre o Usuário voluntariamente no processo de navegação do Site ou quando entrar em contato com a CENTRAL DE ATENDIMENTO , bem como através de Pesquisa de Satisfação.
Finalmente, a Condutor Legal poderá acessar bases de dados públicas ou privadas para confirmar a veracidade dos dados pessoais informados pelo Usuário, inclusive dados relacionados ao pagamento da compra.<br/><br/>
Utilização de dados:<br/>
E-mail<br/>
A Condutor Legal utilizará o e-mail do Usuário prioritariamente para enviar informações sobre suas Compras (confirmação de Compra e atualizações da situação). O e-mail cadastrado também será utilizado para comunicação relacionada aos eventos em que o cliente tiver interesse e para recuperação da senha de acesso do Cliente, em caso de perda ou esquecimento da senha.<br/><br/>
Publicidade via mala direta, e-mail, MMS ou SMS<br/>
No momento do cadastro de seus dados de contato, o Usuário  concorda expressamente em aceitar ou proibir o envio de mensagens publicitárias. Ademais, a qualquer momento, o Usuário poderá alterar sua decisão, por meio de contato com a CENTRAL DE ATENDIMENTO ou, ainda, no campo de descadastro existente nas newsletters enviadas pela Condutor Legal, sendo que em ambos os casos, o descadastramento da base de dados poderá demorar até 5 (cinco) dias para ser efetivada.<br/><br/>
Outras formas<br/>
Além das formas expostas acima, a Condutor Legal poderá, a seu exclusivo critério, utilizar os dados pessoais do Usuário nas seguintes formas:<br/>

(i) atualização de cadastro;<br/>
(ii) garantia da segurança do Usuário;<br/>
(iii) resposta a solicitações do próprio Usuário;<br/>
(iv) informação acerca de alterações nos Termos e Condições de Uso ou das Políticas;<br/>
(v) elaboração de estatísticas com relação ao uso do Site, garantido o anonimato do Usuário, inclusive para fins de aperfeiçoamento e entendimento do perfil dos Usuários para a melhoria do Site;<br/>
(vi) aperfeiçoamento de ferramentas de interatividade entre o Site e o Usuário, garantido seu anonimato;<br/>
(vii) cumprimento de ordens judiciais; e<br/>
(viii) defesa dos direitos da Condutor Legal contra o Usuário em procedimentos judiciais ou administrativos.<br/><br/>
Guarda dos dados<br/>
A Condutor Legal guardará todos os dados coletados em suas bases de dados protegidas e seguras.<br/>
Tais dados serão acessados apenas por processos computadorizados automatizados, profissionais autorizados e nos casos listados nesta Política.<br/>
Caso o Usuário requeira a exclusão de seus dados da base de dados, a Condutor Legal se reserva o seu direito de manter os dados em questão em cópias de salvaguarda por até 5 (cinco) anos, a fim de cumprir obrigações legais de guarda obrigatória.<br/><br/>
5. Compartilhamento e divulgação dos dados<br/>
A Condutor Legal tem a confidencialidade dos dados pessoais do Usuário como prioridade em seus negócios. Assim, assume o compromisso de não divulgar, compartilhar, dar acesso a, facilitar acesso a, alugar, vender, trocar ou de qualquer outra forma disponibilizar tais informações a terceiros, sob nenhum pretexto, exceto nos casos autorizados expressamente pelo Usuário, inclusive nos casos indicados abaixo.   Com o único intuito de permitir a concretização de Compras no Site, a Condutor Legal poderá compartilhar dados pessoais dos Usuários com seus parceiros comerciais, como empresas processadoras de pagamentos, administradoras de cartão de crédito e transportadoras.
Neste caso, serão compartilhados apenas os dados pessoais imprescindíveis para que o parceiro comercial da Condutor Legal desempenhe sua atividade (cobrança, entrega, etc.). Ademais, tais parceiros comerciais serão obrigados, por meio de contratos de confidencialidade, a não arquivar, manter em arquivo, compilar, copiar, reproduzir ou compartilhar tais dados com quem quer que seja.   A outra hipótese de divulgação de dados pessoais é por meio de uma determinação judicial. Também neste caso, a divulgação ocorrerá apenas na medida necessária para cumprir a determinação judicial, permanecendo sigilosos os dados não requeridos pela autoridade em questão.<br/><br/>
6. Dados transmitidos sem solicitação da Condutor Legal<br/>
A Condutor Legal solicita ao Usuário que não envie à Condutor Legal quaisquer informações comerciais, criações pessoais, ideias, fotografias, projetos, conceitos, etc (Conteúdos Não Solicitados). Tais Conteúdos Não Solicitados serão sumariamente descartados, sem qualquer leitura ou incorporação às bases de dados da Condutor Legal. Nos termos da Lei de Direitos Autorais, não são suscetíveis de proteção no Brasil as ideias, concepções abstratas, projetos, planos e esquemas.<br/>
Desta forma, o eventual uso pela Condutor Legal de quaisquer Conteúdos Não Solicitados será decorrente de desenvolvimento interno e independente e poderá ocorrer livremente, não sendo devida ou exigida qualquer autorização ou compensação ao usuário ou consumidor.<br/>
A Condutor Legal desenvolve de forma independente todas as suas políticas e atividades, rechaçando desde já qualquer acusação ou alegação de aproveitamento de Conteúdos Não Solicitados.<br/><br/>
7. Medidas de segurança<br/>
Recursos tecnológicos<br/>
A Condutor Legal adota recursos tecnológicos avançados para garantir a segurança de todos os dados pessoais coletados e armazenados. Nem mesmo os funcionários da Condutor Legal têm livre acesso à base de dados dos Usuários, sendo este limitado apenas àquelas pessoas cujas funções exigem o contato com dados pessoais. Entre as medidas de segurança implementadas, estão a utilização de modernas forma de criptografia e a instalação de barreiras contra o acesso indevido à base de dados, bem como a utilização de sistema antifraude compatível com o mercado de e-commerce. Tais medidas podem ser verificadas pelo Usuário acessando o Site pela visualização do “cadeado de segurança” em seu navegador de internet.<br/><br/>
Sigilo da senha<br/>
A Condutor Legal recomenda que o Usuário mantenha sua senha sob total sigilo, evitando a sua divulgação a terceiros. A Condutor Legal nunca solicitará ao Usuário que informe sua senha fora do Site, por telefone, e-mail ou por qualquer outro meio de comunicação. A senha do Usuário deverá ser usada exclusivamente no momento do acesso à Conta do Usuário no Site. Caso o Usuário suspeite que sua senha tenha sido exposta a terceiros, a Condutor Legal recomenda a imediata substituição da senha mediante contato formal com a Central de Atendimento em um dos canais supracitados.<br/><br/>
E-mails suspeitos<br/>
A Condutor Legal envia ao Usuário apenas e-mails com mensagens publicitárias, divulgando produtos e serviços ou atualizando informações. A Condutor Legal não envia mensagens<br/>
(i) solicitando dados pessoais do Usuário;<br/>
(ii) solicitando a senha ou dados financeiros do Usuário;<br/>
(iii) com arquivos anexos exceto documentos em PDF; ou<br/>
(iv) com links para download de arquivos. Caso receba um e-mail com tais características, desconsidere-o e entre em contato com o CENTRAL DE ATENDIMENTO.<br/><br/>
Cartões de crédito, débito e boleto<br/>
O procedimento de aprovação do pagamento ocorre entre o Usuário, os bancos e as administradoras de cartões, sem intervenção da Condutor Legal.
Impossibilidade de responsabilização.<br/>
Em que pese os maiores esforços da Condutor Legal, o atual estágio da tecnologia não permite que se crie uma base de dados absolutamente segura contra ataques.
Desta forma, a Condutor Legal exime-se de qualquer responsabilidade por danos eventualmente causados por terceiros, inclusive por invasões no Site ou na base de dados, por vírus ou por vazamento de informações, a menos que fique comprovada exclusiva culpa da Condutor Legal.<br/><br/>

8. Direitos das pessoas sobre os dados coletados<br/>
A Condutor Legal permite que o Usuário faça diferentes tipos de cadastro, contendo mais ou menos informações de acordo com seu próprio objetivo no Site. Assim, o Usuário tem a possibilidade de escolher a forma de cadastro, devendo preenchê-lo com informações verídicas e atualizadas.<br/>
O Usuário declara ser o legítimo titular de seus dados pessoais e poderá, a qualquer momento, utilizar as ferramentas de contato supracitadas para editá-los, atualizá-los ou removê-los preventivamente de nossa base de dados.<br/>
A Condutor Legal manterá os dados preventivamente removidos em sigilo pelo prazo de seis meses, para atender obrigações legais de guarda obrigatória, descartando-os definitivamente após tal período.<br/>
A Condutor Legal disponibiliza ainda ferramentas para que o Usuário possa determinar alguns usos de seus dados pessoais, como a autorização para envio de peças de publicidade.<br/><br/>

8. Direitos das pessoas sobre os dados coletados<br/>
9. Uso de dados em caso de alteração de controle da Condutor Legal<br/>
Os dados coletados podem ser eventualmente transferidos a um terceiro em caso de alteração do controle, de uma aquisição, de uma incorporação, de uma fusão ou de uma venda de ativos da Condutor Legal sob qualquer meio ou forma.<br/><br/>

10. Política de dados de menores<br/>

O Site não é direcionado a menores de 18 (dezoito) anos. No entanto, o acesso ao Site não é proibido aos menores, uma vez que não há qualquer conteúdo restrito por questões etárias. Os formulários e questionários do site da Condutor Legal não visam obter dados de menores.<br/>

Caso tais dados sejam inseridos por menores, seu representante legal poderá contatar a CENTRAL DE ATENDIMENTO para retificar, modificar ou remover tais dados.
       </>
   );
}