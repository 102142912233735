import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewRecorrer from 'components/View/ViewRecorrer';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    myFormView
  } from "assets/jss/material-dashboard-react.js";
  

  const myFormViewStyle = makeStyles(myFormView);
  
  const styles = {
    cardNome: {
      color: "rgba(0,0,0,.62)",
      margin: "0",
      fontSize: "18px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
    cardCnh: {
      color: "rgba(0,0,0,.62)",
      margin: "0",
      fontSize: "18px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "300",
      textAlign: "center",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
    }, 
    cardPontos: {
      color: "rgba(3,169,244,.62)",
      margin: "0",
      fontSize: "30px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
    
    myBoxLabel: {
      marginTop: "0px",
      fontWeight: "bold"
    },
    myBoxValue:
    {
      textAlign: "right"
    },
    gridContainer: 
    {paddingTop: "20px", paddingBottom: "30px", height: "10px", borderBottom: " solid 1px #EEEEEE", backgroundColor: ""}
    ,
    appBar: {
        position: 'relative',
        backgroundColor: '#00ACC1'
    },
    root: {
        background: 'transparent',
        boxShadow: 'none'
    },
    fabButton: {
        position: 'fixed',
        right: '40%',
        bottom: '60px',
        width: '250px',
        backgroundColor: '#0aed0a',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#0aed0a',
            color: '#FFFFFF',
        }
    },
    fabButtonMobile: {
        position: 'fixed',
        right: '20%',
        bottom: '25px',
        width: '250px',
        backgroundColor: '#0aed0a',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#0aed0a',
            color: '#FFFFFF',
        }
    }
  };
  
  const useStyles = makeStyles(styles);
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewMulta(props) {

    const [open, setOpen] = useState(false);      
    const classes = useStyles();
    const classesFormView = myFormViewStyle();
    const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const matches = useMediaQuery('(max-width:600px)');
  
    function confirmarRecorrer() {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

      function calcularAlturaLinha(descricao) {

        let maxLength = 20;

        if(window.innerWidth <= 320)
            maxLength = 20;
        else if(window.innerWidth <= 425)
            maxLength = 25;
        else if(window.innerWidth <= 768)
                maxLength = 50;
        else if(window.innerWidth <= 1024)
                maxLength = 50;
        else if(window.innerWidth > 1024)
                maxLength = 200;

        return descricao.length > maxLength ? ((Math.floor(descricao.length / maxLength)+1) * 33)  : 25
    }


  return (
    <>
        <Dialog 
            fullScreen 
            open={open} 
            TransitionComponent={Transition} 
            maxWidth={'sm'}
            aria-labelledby="max-width-dialog-title"
            >
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <ViewRecorrer Multa={props.multa} EVeiculo={true} setOpen={setOpen} />     
        </Dialog>
        <div  style={{paddingTop: "35px"}}>
            <GridContainer>
                <GridItem lg={2}></GridItem>
                <GridItem xs={12} sm={12} md={12} lg={8}>
                    <Card>            
                        <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>Renavan - {props.multa.Veiculo.NumeroRenavam}</h4>
                        </CardHeader>
                        <CardBody profile>
                            <h6 className={classes.cardNome}>{props.multa.Veiculo.Modelo}</h6>
                            <h6 className={classes.cardNome}>{props.multa.Veiculo.Placa}</h6>
                        </CardBody>
                        <CardBody>
                            <div style={{display: (props.multa.Situacao ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}><b>{props.multa.Situacao}</b></div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.AutoInfracao ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={6} sm={6} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Auto Infração</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.AutoInfracao}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.AutoRenainf ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={6} sm={6} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Auto Renainf</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.AutoRenainf}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.DataInfracao ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={4} sm={4} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Infração</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{moment(props.multa.DataInfracao).format("DD/MM/YYYY")} {props.multa.HoraInfracao}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={7} sm={6} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Data Para Pgto</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={5} sm={6} md={9}  style={{display: (props.multa.DatPagamentoComDesconto ? '' : 'none')}}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{moment(props.multa.DatPagamentoComDesconto).add(1, 'days').format("DD/MM/YYYY")}</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={5} sm={6} md={9}  style={{display: (props.multa.DatPagamentoComDesconto ? 'none' : '')}}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>Não Informada</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.Descricao ? '' : 'none')}}>
                                <GridContainer style={myFormView.gridContainer2}>
                                    <GridItem xs={4} sm={3} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Descrição</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={8} sm={9} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.Descricao}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <Hidden xsDown>
                            <div style={{display: (props.multa.EnquadramentoInfracao ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={6} sm={3} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Enquadramento</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={9} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.EnquadramentoInfracao}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            </Hidden>
                            
                            <div style={{display: (props.multa.LocalInfracao ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer2}>
                                    <GridItem xs={2} sm={2} md={2}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Local</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={10} sm={10} md={10} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.LocalInfracao}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            
                            <div style={{display: (props.multa.OrgaoEmissor ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={6} sm={4} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Orgão Emissor</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={8} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.OrgaoEmissor}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.StatusPagamento ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={6} sm={4} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Status Pgto</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={8} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{props.multa.StatusPagamento}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.ValorOriginal ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={7} sm={4} md={3}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Valor Original</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={5} sm={8} md={9} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom + ' ' + classesFormView.colorRed}>R$ {props.multa.ValorOriginal}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.ValorSerPago ? '' : 'none')}}>
                                <GridContainer  style={myFormView.gridContainer}>
                                    <GridItem xs={7} sm={4} md={5}>
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxLabel}>Valor a ser Pago</div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={5} sm={8} md={7} >
                                        <div className={classesFormView.box}>
                                            <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>R$ {props.multa.ValorSerPago}</div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div style={{display: (props.multa.DescricaoFluxoRecurso !== undefined && props.multa.DescricaoFluxoRecurso !== '' ? '' : 'none')}}>
                                <br /><><Chip label={props.multa.DescricaoFluxoRecurso} color={'primary'} />&nbsp;</>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem lg={2}></GridItem>
                <GridItem xs={12} sm={12} md={12} lg={8}>
                    <Card className={classes.root}>
                        <CardBody>
                        <div style={{width: '100%', display: (props.multa.DescricaoFluxoRecurso === undefined || props.multa.DescricaoFluxoRecurso === '' ? '' : 'none')}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Fab variant="extended" classes={{root: !matches ? classes.fabButton : classes.fabButtonMobile}} onClick={confirmarRecorrer}>
                                            Recorrer Agora
                                        </Fab>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    </>
    );
}
export default ViewMulta;