import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemProdutosExtras: {
    fontWeight: 500,
    padding: 5,
    color: "#FFF",
    backgroundColor: "#000"
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  logoPicPay: {
      textAlign: "right"
  },
  helperTextPE: {
    marginTop: -10
  },
  boxHotmart: {
      border: "5px dotted red",
      padding: 15,
      backgroundColor: "#EEE"
  },
  itemHotmart: {
      padding: "0px 5px",
      backgroundColor: "yellow",
      marginTop: 10
  },
  labelPE: {
    '& .MuiTypography-root': {
        color: '#000'
    }
  },
  iconArrowPE: {
      marginTop: "-9px",
      marginRight: "3px",
      color: "red"
  }
}));

const GreenCheckbox = withStyles({
    root: {
        color: '#000',
        '&$checked': {
          color: '#000',
        },
      },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export default function Review(props) {
    const classes = useStyles();
    const [checkboxPE, setCheckboxPE] = useState(new Map());
    const [totalVenda, setTotalVenda] = useState(0);

    useEffect(() => {          
        total_venda();
    },[props] );

    let total_plano = 0;
    
    function total_venda() {
        props.items.map(function(plano) {
            let periodicidade = 0;
            if (plano.Periodicidade == "A") {
                periodicidade = 12;
            } else if(plano.Periodicidade == "S") {
                periodicidade = 6;
            } else if (plano.Periodicidade == "M") {
                periodicidade = 1;
            }
            total_plano = (total_plano + (parseFloat(plano.Preco) * periodicidade));
        });
        props.pesSolo.map(function(item) {
            if (item.Quantidade) {
                total_plano = total_plano + item.Preco * item.Quantidade;
            } else total_plano = total_plano + item.Preco;
        });
        setTotalVenda(total_plano)
    }
    //total_venda();

    function imprimeVigencia(vigencia) {
        let vigencia_formatada = "";
        if (vigencia == 'A') {
            vigencia_formatada = "Periodicidade Anual";
        } else if(vigencia == 'S') {
            vigencia_formatada = "Periodicidade Semestral";
        } else if(vigencia == 'M') {
            vigencia_formatada = "Periodicidade Mensal";
        }
        return vigencia_formatada;
    }

    function formataValor(valor) {
        return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    function handleChangeCheckbox(e) {
        const valorPE = e.target.value;
        const item = e.target.name;
        const isChecked = e.target.checked;
        setCheckboxPE(checkboxPE.set(item, isChecked));
        
        if(isChecked) {
            setTotalVenda(totalVenda + parseFloat(valorPE));
        } else {
            setTotalVenda(totalVenda - parseFloat(valorPE));
        }
        props.handleChangeGuidsPE(item)
    }

    return (
        <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Resumo do pedido
        </Typography>
        <List disablePadding>
            {props.items.map((plano, index, array) => (
                <ListItem className={classes.listItem} key={index}>
                    <ListItemText primary={plano.Descricao} secondary={imprimeVigencia(plano.Periodicidade)} />
                    <Typography variant="body2">{`${formataValor(plano.Preco)}/mês`}</Typography>
                </ListItem>
            ))}
            {props.pesSolo.map((item, index, array) => (
                <ListItem className={classes.listItem} key={index}>
                    <ListItemText primary={`${item.Quantidade}x - ${item.Nome}`} secondary={item.Descricao} />
                    <Typography variant="body2">{formataValor(item.Preco)}</Typography>
                </ListItem>
            ))}
            <div className={classes.boxHotmart} style={{ display: (props.pes.length > 0) ? 'block' : 'none'  }}>
                <Typography variant="subtitle1" className={classes.listItemProdutosExtras}>
                    Compre +
                </Typography>
                <div className={classes.itemHotmart}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {props.pes.map((item, index, array) => (
                                <div key={item.Guid}>
                                    <ArrowForwardIcon className={classes.iconArrowPE} />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={checkboxPE.get(item.name)} value={item.ValorProduto} name={item.Guid} onChange={handleChangeCheckbox} />}
                                        label={`${item.NomeProduto} - ${formataValor(item.ValorProduto)}`}
                                        className={classes.labelPE}
                                    />
                                    <FormHelperText className={classes.helperTextPE}>{item.DescricaoProduto}</FormHelperText>
                                </div>
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
            <ListItem className={classes.listItem}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" className={classes.total}>
                {formataValor(totalVenda)}
            </Typography>
            </ListItem>
        </List>
        <Grid container spacing={2}>
            <Grid item container direction="column" xs={12} sm={12}>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Dados de pagamento
            </Typography>
            <Grid container>
                <React.Fragment>
                    <Grid item xs={6}>
                        <Typography gutterBottom>Método de pagamento</Typography>
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.logoPicPay }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" viewBox="0 0 129 44"><path fill="#21c25e" d="M28 34.3h5.8V17.4H28V34.3zM35.8 5.9h-3.9v3.9h3.9V5.9zM13.9 7.8H8.3v4.9h5.2c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7H8.3v-9.2H2.5v21.5h5.8v-7.4h5.5c6.7 0 10.6-3.6 10.6-9.8C24.4 11.3 20.6 7.8 13.9 7.8zM39.7 2H28v11.7h11.7V2zM37.8 11.7H30V3.9h7.8V11.7zM71.8 7.8h-5.3v4.9h5c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7h-5v-9.2h-5.8v21.5h5.8v-7.4h5.3c6.7 0 10.6-3.6 10.6-9.8C82.4 11.3 78.5 7.8 71.8 7.8zM120.5 14l-5 12.6 -5-12.6h-6l8 20.3 -3.1 7.7h6.1l11-28H120.5zM94.5 13.9c-3.5 0-6.2 0.8-9.2 2.3l1.8 4c2.1-1.2 4.2-1.8 6.1-1.8 2.8 0 4.2 1.2 4.2 3.4v0.4h-5.6c-5 0-7.7 2.3-7.7 6.1 0 3.7 2.6 6.3 7 6.3 2.8 0 4.8-1 6.4-2.7v2.2h5.7l0-13.2C103 16.6 99.9 13.9 94.5 13.9zM97.9 27.5c-0.6 1.7-2.3 3.1-4.7 3.1 -2 0-3.2-1-3.2-2.6 0-1.6 1.1-2.3 3.3-2.3h4.6V27.5zM48.6 29.9c-2.8 0-4.8-2.2-4.8-5.5 0-3.2 2-5.4 4.8-5.4 2 0 3.5 0.8 4.6 2.2l3.9-2.8c-1.8-2.7-4.9-4.3-8.8-4.3C42.2 14 38 18.2 38 24.4c0 6.2 4.2 10.3 10.3 10.3 4.2 0 7.3-1.7 9-4.5l-4-2.7C52.3 29.1 50.7 29.9 48.6 29.9z"></path></svg>
                    </Grid>
                </React.Fragment>
            </Grid>
            </Grid>
        </Grid>
        </React.Fragment>
    );
}