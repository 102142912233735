import React from "react";
// @material-ui/core components
import ViewCondutor from "components/View/ViewCondutor.js";
import LoadingFull from "components/Loading/LoadingFull";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';

  export default class CondutorView extends React.Component {
  
    constructor(props) {
      super(props);

      checaLiberacaoMenu(props);
  
      this.state  = {
        condutor: {Multas:[], PontosPeriodos: []},
        loadingGrid: true,
        isErro: false
      };
      
      
    } 
    componentDidMount(){
  
      let guid = this.props.match.params.guid;
      this.setState({isErro: false});

      api.get(serverApi.url + serverApi.versao + '/Habilitacao/Guid/' + guid + '/Resumo').then(response => {
        this.setState({
          condutor: response.data
        });
        
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
        this.setState({isErro: true}); 
       
      })
      .finally(() => {   
        this.setState({
          loadingGrid: false
        });   
      });
      
    }
    
    render() {
      return (
        <>
            <LoadingFull display={this.state.loadingGrid} />
            
              <ViewCondutor condutor={this.state.condutor} />
            <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} />
        </>
      );
  }
}
