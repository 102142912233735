import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import iconCar from "assets/img/icon-car.png";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500
  },
  inline: {
    display: 'inline',
    fontFamily: 'Montserrat, sans-serif',
  },
  chipCustom: {
    '& .MuiChip-label': { 
      whiteSpace: 'initial'
    }
  },
  itemList: {
    position: 'relative', 
    backgroundColor: '#f5f5f5',
    marginBottom: '15px',
    borderRadius: '10px',
    marginRight: '5px'
  },
  statusDisplay: {
      width: 10,
      height: 10,
      display: 'inline-block',
      borderRadius: '5px',
      marginRight: '5px'
  },
  boxStatus: {
      marginRight: '7px',
      fontFamily: 'Montserrat, sans-serif',
  },
  textStatusDisplay: {
      color: '#787878'
  },
  headerDivider: {
    borderLeft: '1px solid #e2e2e2', 
    borderRight: '1px solid #e2e2e2',
    height: '77px',
    position: 'absolute',
    left: '57px',
    top: '35px' 
  },
  headerDividerDesativado: {
    borderLeft: '1px solid #e2e2e2', 
    borderRight: '1px solid #e2e2e2',
    height: '57px',
    position: 'absolute',
    left: '57px',
    top: '20px' 
  },
  imgIconCar: {
      width: '10%',
      top: '52px',
      marginRight: '30px',
      position: 'relative',
  },
  imgIconCarDesaticado: {
    width: '10%',
    top: '25px',
    marginRight: '30px',
    position: 'relative',
  }
}));


 function GridVeiculo(props) {

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');

    const colo = {backgroundColor: 'green', color: 'white'};
  return (
    <>
    <div style={{display: (props.display ? '' : 'none')}}>
      <List className={classes.root}>      
        {props.veiculos.map((veiculo, index) => {
          return ( 
            <React.Fragment key={veiculo}>
              <Link to={"veiculos/view/" + veiculo.Guid} >
                <ListItem alignItems="flex-start" className={classes.itemList}>
                  
                    <img src={iconCar} alt="Carro ícone" className={veiculo.Ativo ? classes.imgIconCar : classes.imgIconCarDesaticado} />
                  
                  { matches ? (<div className={veiculo.Ativo ? classes.headerDivider : classes.headerDividerDesativado}></div>) : ("") }
                  <ListItemText
                    primary={<React.Fragment>
                      <Typography
                          component="span"
                          className={classes.titulo}
                          color="textPrimary"
                        >
                        {veiculo.Placa}
                      </Typography>
                      </React.Fragment>}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {veiculo.NumeroRenavam}
                        </Typography>&nbsp;<br />
                        { veiculo.Chips.map((chip, key) => {
                            if(matches) {
                                return <p key={key} style={{marginBottom: 0}}><span className={classes.boxStatus}><span className={classes.statusDisplay} style={{backgroundColor: chip.Style.backgroundColor}}></span><span className={classes.textStatusDisplay}>{chip.Label}</span></span></p>
                            } else {
                                return <span key={key} className={classes.boxStatus}><span className={classes.statusDisplay} style={{backgroundColor: chip.Style.backgroundColor}}></span><span className={classes.textStatusDisplay}>{chip.Label}</span></span>
                            }
                        }) }
                      </React.Fragment>
                    }
                  />
                </ListItem>
                </Link>
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </>
    );
}
export default GridVeiculo;