import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import LoadingFull from "components/Loading/LoadingFull";
import Icon from "@material-ui/core/Icon";
import CardAvatar from "components/Card/CardAvatar.js";
import Tooltip from '@material-ui/core/Tooltip';
import InputMask from 'react-input-mask';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import estados from '../../services/estados.json';
 
export default class VeiculoCorrigirCadastro extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state  = {
        VeiculoId: {},
        NumeroRenavam: '',      
        Placa: '',      
        UF: {"Uf": "RJ"},
        ProprietarioDocumento: '',
        EmailNotificacao: '',
        blocking: false,
    };  
  } 
  componentDidMount(){

    let id = this.props.match.params.id;
      this.setState({isErro: false});

      api.get(serverApi.url + serverApi.versao + '/Veiculo/' + id + '/Resumo').then(response => {
        this.setState({VeiculoId: response.data.Id});
        this.setState({NumeroRenavam: response.data.NumeroRenavam});
        this.setState({Placa: response.data.Placa});
        let cpf_cnpj = "";
        if(response.data.ProprietarioDocumento != "" && response.data.ProprietarioDocumento.length > 0) {
          cpf_cnpj = this.formata_cpf_cnpj(response.data.ProprietarioDocumento);
        }
        this.setState({ProprietarioDocumento: cpf_cnpj});
        this.setState({EmailNotificacao: response.data.EmailNotificacao});
        if(response.data.UF != null) {
          this.setState({UF: {"Uf": response.data.UF.Uf}});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({isErro: true}); 
      })
      .finally(() => {   
        this.setState({
          loadingGrid: false
        });   
      });

  }

  corrigirCadastro = () => {
    
    if(!this.validateForm() )
    {      
        alert('Todos os campos devem ser preenchidos!');
      return;
    }

    let cpf_cnpj = this.state.ProprietarioDocumento;
    cpf_cnpj = this.limpa_formatacao_cpf_cnpj(cpf_cnpj);
    
    this.setState({blocking: true});

    var querystring = require('querystring');
    
    const data = querystring.stringify({Id: this.state.VeiculoId, NumeroRenavam: this.state.NumeroRenavam, Placa: this.state.Placa, UF: this.state.UF.Uf, ProprietarioDocumento: cpf_cnpj, EmailNotificacao: this.state.EmailNotificacao})
    
    api.post(serverApi.url + serverApi.versao + '/Veiculo/CorrigeCadastro', data).then(response => {
      // handle success
        console.log(response.data.access_token);
        alert('Sucesso!!!!\nO cadastro do veículo foi atualizado com sucesso!');

        this.props.history.push('/app/veiculos');
    })
    .catch(error => {
      // handle error
      console.error(error.response);
      try{
        switch(error.response.data)
        {
          case 'LIMITE_MAX_ATINGIDO':
              alert('O limite de veículos foi atingido!\nEntre em contato conosco e solicite uma mudança no seu plano!');
          break;
          
          default:
              alert(error.response.data);
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar a atualização do cadastro do veículo!\nTente novamente!');

      }
      this.setState({blocking: false});
    })
    .finally( ()=> {
      //this.setState({blocking: false});
    });

   };

  limpa_formatacao_cpf_cnpj(cpf_cnpj) {
    if(cpf_cnpj.length <= 14) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    } else if(cpf_cnpj.length <= 18) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("/", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    }
    return cpf_cnpj;
  }
  
  formata_cpf_cnpj(cpf_cnpj) {
    let valor = "";
    if(cpf_cnpj.length == 11) {
      valor = cpf_cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4");
    } else if(cpf_cnpj.length == 14) {
      valor = cpf_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5");
    }
    return valor;
  }

  validateForm() {
    return this.state.NumeroRenavam.length > 0 && this.state.Placa.length > 0;
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeEstado = event => {
    this.setState({
      [event.target.id]: {"Uf": event.target.value}
    });
  }

  //ESPECÍFICA PARA LIMPAR FORMATAÇÃO DE CPF
  handleChangeCPF = event => {
    let valor = event.target.value;
    
    let valor_sem_mask = this.limpa_formatacao_cpf_cnpj(valor);
    if(valor_sem_mask.length == 11) {
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4");
    } else if(valor_sem_mask.length == 14) {
      valor = this.limpa_formatacao_cpf_cnpj(valor);
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5");
    }

    this.setState({
      [event.target.id]: valor
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  
  render() {
  return (
      <GridContainer>
      <LoadingFull display={this.state.blocking} />
            <CardAvatar profile style={{marginTop: "10px", width: '70px', textAlign: 'center', paddingTop: '5px'}}>
            <Icon style={{fontSize: "50px"}}>directions_car</Icon>
            </CardAvatar>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer>
              <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                    <TextField
                         id="NumeroRenavam"
                         label="Renavam"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.NumeroRenavam}
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer >
              <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                    <TextField
                        id="Placa"
                        label="Placa"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.Placa}
                        fullWidth
                        inputProps={{ maxLength: 11 }}
                      />
                </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {/* <InputMask mask="999.999.999-99" 
                    maskChar=" "
                    value={this.state.ProprietarioDocumento} 
                    onChange={this.handleChangeCPF}
                    required  
                  >
                    {(inputProps) => <TextField {...inputProps} 
                      style={{marginTop : '15px'}}
                      id="ProprietarioDocumento"
                      label="CPF"
                      margin="dense"
                      fullWidth
                    />}
                  </InputMask> */}
                  <TextField
                    id="ProprietarioDocumento"
                    label="CPF/CNPJ"
                    margin="dense"
                    onChange={this.handleChangeCPF}
                    value={this.state.ProprietarioDocumento}
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={10} sm={11} md={11}>
                      <TextField
                          id="EmailNotificacao"
                          label="E-mail Notificaçao"
                          margin="dense"
                          value={this.state.EmailNotificacao}
                          onChange={this.handleChange}
                          helperText=""
                          fullWidth
                        />
                    </GridItem>
                    <GridItem md={1}>
                      <Tooltip title="Se preferir, indique um email para ser copiado nas notificações desse veículo">
                          <Icon className="icon_info_email">info</Icon>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                <FormControl style={{marginTop: 26, width: '100%'}}>
                    <Select
                      native
                      id="UF"
                      value={this.state.UF.Uf}
                      onChange={this.handleChangeEstado}
                      required
                      inputProps={{
                        disabled: true
                      }}
                    >
                      {estados.map((currentValue, index, arr) => 
                        <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
            </GridContainer>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer>
              <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"center"}}>
                <Button color="info" onClick={this.corrigirCadastro}>Corrigir</Button>
                </GridItem>
            </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}