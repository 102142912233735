import React from "react";
//import { Link } from "react-router-dom";
//import axios from "axios";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import LoadingFull from "components/Loading/LoadingFull";
import Icon from "@material-ui/core/Icon";
import CardAvatar from "components/Card/CardAvatar.js";
import Tooltip from '@material-ui/core/Tooltip';
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import estados from '../../services/estados.json';
import './styles.css'
 
export default class VeiculoCadastrar extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    console.log("aaa", props.location.state);
    let qtdVeiculoss = 0;
    if(typeof props.location.state !== 'undefined') {
      qtdVeiculoss = props.location.state.qtdVeiculos;
    } 
    

    this.state  = {
      NumeroRenavam: '',      
      Placa: '',
      EmailNotificacao: '',
      UF: {"Uf": "RJ"},
      blocking: false,
      quantidadeVeiculos: (qtdVeiculoss+1),
    };  
  }

  componentDidMount() {  }
  
  cadastrarVeiculo = () => {
    
    if(!this.validateForm() )
    {      
        alert('Todos os campos devem ser preenchidos!');
      return;
    }
    
    this.setState({blocking: true});

    let cpf_cnpj = this.state.ProprietarioDocumento;
    cpf_cnpj = this.limpa_formatacao_cpf_cnpj(cpf_cnpj);
    
    var querystring = require('querystring');
    const data = querystring.stringify({ NumeroRenavam: this.state.NumeroRenavam, Placa: this.state.Placa, EmailNotificacao: this.state.EmailNotificacao, ProprietarioDocumento: cpf_cnpj, UF: this.state.UF.Uf})
    
    api.post(serverApi.url + serverApi.versao + '/Veiculo/Cadastrar', data).then(response => {
      // handle success
        //console.log(response.data.access_token);
        alert('Sucesso!!!!\nO cadastro do veículo foi realizado com sucesso!');

        this.props.history.push('/app/veiculos');
    })
    .catch(error => {
      // handle error
      console.error(error.response);
      try{
        switch(error.response.data)
        {
          case 'LIMITE_MAX_ATINGIDO':
              alert('O limite de veículos foi atingido!\nEntre em contato conosco e solicite uma mudança no seu plano!');
          break;
          
          default:
              alert(error.response.data);
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar o cadastro do veículo!\nTente novamente!');

      }
      this.setState({blocking: false});
    })
    .finally( ()=> {
      //this.setState({blocking: false});
    });

   };

  validateForm() {
    return this.state.NumeroRenavam.length > 0 && this.state.Placa.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeEstado = event => {
    this.setState({
      [event.target.id]: {"Uf": event.target.value}
    });
  }

  limpa_formatacao_cpf_cnpj(cpf_cnpj) {
    if(cpf_cnpj.length <= 14) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    } else if(cpf_cnpj.length <= 18) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("/", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    }
    return cpf_cnpj;
  }

  //ESPECÍFICA PARA LIMPAR FORMATAÇÃO DE CPF
  handleChangeCPF = event => {
    let valor = event.target.value;
    
    let valor_sem_mask = this.limpa_formatacao_cpf_cnpj(valor);
    if(valor_sem_mask.length == 11) {
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4");
    } else if(valor_sem_mask.length == 14) {
      valor = this.limpa_formatacao_cpf_cnpj(valor);
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5");
    }

    this.setState({
      [event.target.id]: valor
    });
   /*  this.setState({
      [event.target.id]: event.target.value.replace(".","").replace(".","").replace("-","")
    }); */
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  
  render() {
  return (
      <GridContainer>
      <LoadingFull display={this.state.blocking} />
            <CardAvatar profile style={{marginTop: "12px", textAlign: 'center', width: '60px'}}>
            <Icon style={{fontSize: "50px"}}>directions_car</Icon>
            </CardAvatar>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                    <TextField
                        id="NumeroRenavam"
                        label={`Renavam do Veículo [${this.state.quantidadeVeiculos}]`}
                        margin="dense"
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 15 }}
                        fullWidth
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                    <TextField
                        id="Placa"
                        label={`Placa do Veículo [${this.state.quantidadeVeiculos}]`}
                        margin="dense"
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 12 }}
                        fullWidth
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                  {/* <InputMask mask="999.999.999-99" 
                    maskChar=" "
                    value={this.value} 
                    onChange={this.handleChangeCPF}
                    required  
                  >
                    {(inputProps) => <TextField {...inputProps} 
                      style={{marginTop : '15px'}}
                      id="ProprietarioCpf"
                      label={`CPF do Proprietário do Veículo [${this.state.quantidadeVeiculos}]`}
                      margin="dense"
                      fullWidth
                    />}
                  </InputMask> */}
                  <TextField
                    id="ProprietarioDocumento"
                    label="CPF/CNPJ"
                    margin="dense"
                    onChange={this.handleChangeCPF}
                    value={this.state.ProprietarioDocumento}
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                  />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={10} sm={11} md={11}>
                      <TextField
                        id="EmailNotificacao"
                        label={`E-mail para Avisos do Veículo [${this.state.quantidadeVeiculos}]`}
                        margin="dense"
                        onChange={this.handleChange}
                        helperText=""
                        fullWidth
                      />
                    </GridItem>
                    <GridItem md={1}>
                      <Tooltip title="Se preferir, indique um email para ser copiado nas notificações desse veículo">
                          <Icon className="icon_info_email">info</Icon>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                  <FormControl style={{marginTop: 26, width: '100%'}}>
                    <Select
                      native
                      id="UF"
                      value={this.state.UF.Uf}
                      onChange={this.handleChangeEstado}
                      required
                      inputProps={{
                        disabled: true
                      }}
                    >
                      {estados.map((currentValue, index, arr) => 
                        <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
            </GridContainer>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                <Button color="info" size="md" onClick={this.cadastrarVeiculo}>Cadastrar</Button>
                </GridItem>
            </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}