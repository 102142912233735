import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GridVeiculo from "components/Grid/GridVeiculo.js";
import GridVeiculoV2 from "components/Grid/GridVeiculoV2";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip'
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';
import Button from 'components/CustomButtons/Button';

import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
 
export default class Veiculos extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state  = {
      veiculos: [{Chips :[{Style : {backgroundColor: ''}}]}],
      loadingGrid: true,
      isErro: false,
      totalVeiculos: 0
    };
  } 
  componentDidMount(){
    this.carregar();
  }

  carregar = () => {
    
    this.setState({isErro: false});

    api.get(serverApi.url + serverApi.versao + '/Veiculo/Veiculos').then(response => {
      this.setState({ veiculos: response.data});
      //qconsole.log(response.data);
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true}); 
    })
    .finally(() => {   
      this.setState({loadingGrid: false});   
    });

    //VERIFICA SE TEM VEÍCULOS
    this.setState({loadingGrid: true});
    var formdata = new FormData();
    let email_user = localStorage.getItem("CLLogin");
    formdata.append('function', 'get_veiculos_usuario');
    formdata.append('email', email_user);

    api.post(serverApi.urlPHP + '/apicdl/usuario.php', formdata).then(response => {
      //console.log("resposta veiculos user", response.data);
      this.setState({totalVeiculos: parseInt(response.data.TotalVeiculos)});
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true}); 
    })
    .finally(() => {   
      this.setState({
        loadingGrid: false
      });   
    });
  }

  clickCadastarVeiculo = () => {
    this.props.history.push({
      pathname: `veiculos/cadastrar`,
      state: { qtdVeiculos: this.state.totalVeiculos }
    });
  }
  
  render() {
  return (
      //<GridContainer>
      <>
        <LoadingGrid display={this.state.loadingGrid} />
        <div style={{display: (!this.state.loadingGrid ? '' : 'none'), width: '100%'}}>
          <MeuPlanoAtual />
          <GridVeiculoV2 display={!this.state.loadingGrid} veiculos={this.state.veiculos} />
          {this.state.veiculos.length <= 0 ? (
              <GridContainer style={{textAlign: 'center'}}>
                <GridItem xs={12} sm={12} md={12}>
                  <p>Olá! Você ainda não fez o cadastro do seu veículo no App. Faça agora e descubra se cometeu alguma infração de trânsito. </p>
                  <Button type="button" onClick={this.clickCadastarVeiculo} style={{backgroundColor: '#00acc1', margin: '50px 0'}}>Cadastrar Veículo</Button>
                </GridItem>
              </GridContainer>
            ) :
            ('')}
            <Tooltip title="Adicionar novo Veículo" aria-label="Adicionar novo Veículo" style={{position: 'fixed', bottom: '35px',right: '20px'}}>
            <a onClick={this.clickCadastarVeiculo}>
              <Fab>
                <AddIcon />
              </Fab>
            </a>
          </Tooltip>
        </div>
          <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} />
     </>
    );
  }
}