import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { 
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot
} from '@material-ui/lab';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

const styles = {
    secondaryTail: {
        backgroundColor: '#4caf50',
    },
    checkedItem: {
      backgroundColor: '#4caf50'
    },
    inativeText: {
      color: '#bdbdbd'
    },
    mensagemTimeLinePrincipal: {
      marginTop: 30,
      textAlign: 'center',
      fontFamily: 'Montserrat, sans-serif',
    },
    mensagemTimeLineSecundaria: {
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500
    },
    iconArrowDown: {
      fontSize: '59px'
    },
    boxTimeLine: {
      backgroundColor: '#FFF', 
      marginTop: '15px !important'
    },
    itemTimeLine: {
        color: '#000',
        '&::before': {
          flex: 0,
          content: 'none'
        }
    },
    passo: {
      fontFamily: 'Montserrat, sans-serif',
      color: '#787878',
      marginBottom: 2,
    },
    subTituloPasso: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '17px',
      color: '#000000',
      marginBottom: 2,
      fontWeight: 500,
    },
    descricaoPasso: {
      fontFamily: 'Montserrat, sans-serif',
      color: '#787878'
    }
};


function CustomizedTimeline(props) {
  //const classes = useStyles();
  const { classes, children, className, ...other } = props;
  const [primeiroPasso, setPrimeiroPasso] = useState(false);
  const [segundoPasso, setSegundoPasso] = useState(false);
  const [terceiroPasso, setTerceiroPasso] = useState(false);
  const [usuarioNome, setUsuarioNome] = useState();

  useEffect(() => {
   carregaDados(); 
  });

  async function carregaDados() {
    let email_user = localStorage.getItem("CLLogin");

    var formdata = new FormData();
    formdata.append('function', 'get_infos_timeline');
    formdata.append('email', email_user);

    api.post(serverApi.urlPHP + '/apicdl/usuario.php', formdata).then(responseS => {
        //console.log("Plano", responseS);
        let objData = responseS.data;
        setUsuarioNome(objData.Usuario.Nome.split(" ")[0]);
        if(objData.TotalHabilitacoes > 0 || objData.TotalVeiculos > 0) {
          setPrimeiroPasso(true);
          if(objData.TotalMultas > 0) {
            setSegundoPasso(true);
            if(objData.TotalRecursos > 0) {
              setTerceiroPasso(true);
            }
          }
        }
    })
    .catch(error => {
        console.error(error);
    })
    .finally(() => {  });
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}></GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.boxMensagem} style={{backgroundColor: '#FFF'}}>
          <p className={classes.mensagemTimeLinePrincipal}>NUNCA MAIS Seja SURPREENDIDO Com MULTAS de Trânsito e BLOQUEIOS na sua CNH. A Condutor Legal CUIDA do seu Direito de Dirigir.</p>
          <p className={classes.mensagemTimeLineSecundaria}>BLINDE SUA CNH AGORA!</p>
          <p style={{textAlign: 'center'}}><ExpandMoreIcon className={classes.iconArrowDown} /></p>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}></GridItem>
      </GridContainer>
      
      <div className={classes.boxTimeLine}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{backgroundColor: '#FFF'}}>
            <Timeline>
              <TimelineItem className={clsx(classes.itemTimeLine, className)} {...other}>
                <TimelineSeparator>
                  <TimelineDot className={primeiroPasso ? classes.checkedItem : ''}>
                    <DriveEtaIcon />
                  </TimelineDot>
                  <TimelineConnector className={primeiroPasso ? classes.secondaryTail : ''} />
                </TimelineSeparator>
                <TimelineContent>
                  <p className={classes.passo}>1° Passo</p>
                  <p className={classes.subTituloPasso}>Cadastre</p>
                  <p className={classes.descricaoPasso}>CNH e Veículo</p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem className={clsx(classes.itemTimeLine, className)} {...other}>
                <TimelineSeparator>
                  <TimelineDot className={segundoPasso ? classes.checkedItem : ''}>
                    <PageviewIcon />
                  </TimelineDot>
                  <TimelineConnector className={segundoPasso ? classes.secondaryTail : ''} />
                </TimelineSeparator>
                <TimelineContent>
                  <p className={classes.passo}>2° Passo</p>
                  <p className={classes.subTituloPasso}>Consulte</p>
                  <p className={classes.descricaoPasso}>Suas Multas</p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem className={clsx(classes.itemTimeLine, className)} {...other}>
                <TimelineSeparator>
                  <TimelineDot className={terceiroPasso ? classes.checkedItem : ''}>
                    <AssignmentTurnedInIcon />
                  </TimelineDot>
                  <TimelineConnector className={terceiroPasso ? classes.secondaryTail : ''} />
                </TimelineSeparator>
                <TimelineContent>
                  <p className={classes.passo}>3° Passo</p>
                  <p className={classes.subTituloPasso}>Recorra</p>
                  <p className={classes.descricaoPasso}>Suas Multas</p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem className={clsx(classes.itemTimeLine, className)} {...other}>
                <TimelineSeparator>
                  <TimelineDot className={primeiroPasso && segundoPasso && terceiroPasso ? classes.checkedItem : ''}>
                    <DoneOutlineIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <p className={classes.passo}>4° Passo</p>
                  <p className={classes.subTituloPasso}>Parabéns {usuarioNome}!</p>
                  <p className={classes.descricaoPasso}>Você é um CONDUTOR LEGAL!</p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
            </GridItem>
          </GridContainer>
        </div>
      </>
  );
}

CustomizedTimeline.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CustomizedTimeline);