import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
//import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
//import { fontSize } from "@material-ui/system";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  function getRouter() {
   
    var rota = {
      path: "/login",
      name: "",
      rtlName: "قائمة الجدول",
      layout: "/anonimo",
      invisible: false,
      enableBack: false
    };
    props.routes.map(prop => {

      var href = window.location.href;
      var hrefRota = (prop.layout + prop.path);
      var posicaoId = hrefRota.indexOf(':id');

      if(posicaoId !== -1)
      {
        href = window.location.origin + window.location.pathname.substring(0, posicaoId);
        hrefRota = hrefRota.substring(0, posicaoId);
      }

      if (href.indexOf(hrefRota) !== -1) {
        rota = prop;
      }
      
    });
    return rota;
  }

  function makeBrand() {   
    var rota = getRouter();
    return (rota === null?null:rota.name);
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
      <div style={{width: "30px", position: "relative", top: "10px", backgroundColor: "red"}} >
          {getRouter().enableBack && (
              <a href={"javascript: window.history.back()"} style={{position: "absolute", top: "-30px" , color: "#fff"}}>
                <Icon style={{fontSize: "250%", color: "#fff"}}>keyboard_arrow_left</Icon></a>
          )}
        </div>
        <div className={classes.flex}>          
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>       
        </div>
        {makeBrand() == "Notificações" ? <span style={{fontSize: 11, marginBottom: 0, marginLeft: '10px', lineHeight: '15px'}}>Mantenha seu e-mail atualizado para receber atualizações da Condutor Legal</span> : ""}
        <Hidden smDown implementation="css">
          {<AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">{/*Icone de hamburguer */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
