import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
  }));

  
function LoadingGrid(props) {
  
    const classes = useStyles();

    return (
      <div style={{marginTop:'20px', 'width': '100%', textAlign: 'center', 'display': (props.display ? '' : 'none')}}>
        <CircularProgress className={classes.progress} />
      </div>
    );
}
export default LoadingGrid;