import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardLogin from "components/Card/CardLogin";
import CardLoginAvatar from "components/Card/CardLoginAvatar.js";
import CardLoginBody from "components/Card/CardLoginBody.js";
import bgImage1 from "assets/img/background-login-2.png";
import bgImage2 from "assets/img/background-login-2.png";
import bgImage3 from "assets/img/background-login-2.png";
import bgImage4 from "assets/img/background-login-2.png";
import bgImageLogo from "assets/img/logo-login.png";
import TextField from '@material-ui/core/TextField';

import {serverApi} from 'services/constante.js';
import LoadingDefault from "components/Loading/LoadingDefault";
import Snackbar from '@material-ui/core/Snackbar';
import 'react-block-ui/style.css';
import './styleSnackbar.css'
import GA from 'services/GoogleAnalytics'

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4];
const imagem = bgImages[Math.floor(Math.random() * bgImages.length)];

export default class Login extends Component {
  
  constructor(props) {
    super(props);
    
    this.state  = {
      email: localStorage.getItem('CLLogin'),
      password: localStorage.getItem('CLSenha'),
      blocking: false,
      openSnack: false,
      //openSnackInstallApp: jaInstalado
    };
    
    if(localStorage.getItem('CLToken') !== null) {
      this.props.history.push('/app/inicial');
    }
    GA.pageview(window.location.pathname);
  }  
  
  acessar = async () => {
    
    if(!this.validateForm() ) {
      alert('Informe o e-mail e senha!');
      return;
    }
    let THIS = this;
    setTimeout(() => {
    //  THIS.setState({blocking: false});
    }, 2000);
    THIS.setState({blocking: true});
    //console.log('O link foi clicado.');

    var querystring = require('querystring');
    const data = querystring.stringify({ username: this.state.email, password: this.state.password, grant_type: 'password' })

    axios.post(serverApi.url + '/token', data).then(response => {
      // handle success
      localStorage.setItem('CLToken', response.data.access_token);
      localStorage.setItem('CLLogin', THIS.state.email);
      localStorage.setItem('CLSenha', THIS.state.password);

      /*GA.event({
        'category': 'Login',
        'action': 'App',
        'label': THIS.state.email
      });*/

      THIS.props.history.push('/app/inicial');

      //console.log(response.data);
    })
    .catch(error => {
      // handle error
      console.error(error);
      try{
        switch(error.response.data.error_description)
        {
          case 'USUARIO_SENHA_INVALIDO':
            alert('Usuário ou senha inválidos!');
          break;
          case 'USUARIO_BLOQUEADO':
              alert('Usuário bloqueado!\nEnvie um e-mail para: sac@condutorlegal.com');
          break;
          case 'USUARIO_NAO_EXISTE':
              alert('Usuário não cadastrado em nossa base de dados!\nClique no link: Cadastre-se');
          break;
          default:
              alert('Náo foi possível realizar a autenticação!\nTente novamente!');
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar a autenticação!\nTente novamente!');

      }
    })
    .finally( ()=> {
      this.setState({blocking: false});
    });

   };

  validateForm() {
    if(this.state.email === null || this.state.password === null)
    {
      return false;
    }
    return this.state.email.length > 0 && this.state.password.length > 0;
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  handleSnackClose = () => {
    this.setState({
      openSnack: false
    });
  };

  cadastrarSe = () => {
    this.props.history.push(`/cadastrarUsuario`);
  };

  render() {
        return (
          <>
          <div id="bglogin" style={{paddingTop: "15%", backgroundImage: "url(" + imagem + ")", 
          position: "fixed",
          zIndex: "0",
          height: "100%",
          width: "100%",
          top: "-200px",
          left: "0",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center", "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            backgroundColor: '#cccccc',
            opacity: ".8"
          } }}>
          </div>
          <LoadingDefault display={this.state.blocking} />
          <br />
              <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CardLogin>
                    <CardLoginAvatar profile>                      
                      <img src={bgImageLogo} alt="..." style={{width: '35px', margin: "15px 10px 10px 10px"}} />                      
                    </CardLoginAvatar>
                    <CardLoginBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>                            
                          <p style={{ textAlign: 'center', fontSize: 25, fontFamily: 'Montserrat, sans-serif', fontWeight: '500', marginBottom: 10 }}>Condutor Legal</p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>                            
                          <p style={{ textAlign: 'center', fontSize: 15, fontFamily: 'Montserrat, sans-serif', marginBottom: 2 }}>Monitoramento de multas e pontos da cnh</p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>                            
                            <TextField
                              style={{fontFamily: 'Montserrat, sans-serif', marginTop: '25px'}}
                              id="email"
                              label="E-mail"
                              margin="dense"
                              onChange={this.handleChange}
                              fullWidth
                              required
                              inputProps={{ maxLength: 150 }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            style={{fontFamily: 'Montserrat, sans-serif', marginTop: '10px'}}
                            id="password"
                            label="Senha"
                            margin="dense"
                            type="password"
                            onChange={this.handleChange}
                            fullWidth
                            required
                            inputProps={{ maxLength: 12 }}
                          />
                          </GridItem>
                      </GridContainer>
                      <br />
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center", height : '65px'}}>
                            <Button style={{fontFamily: 'Montserrat, sans-serif', backgroundColor: '#03A9F4', borderRadius: '20px', marginBottom: 0}} fullWidth color="info" onClick={this.acessar}>ENTRAR</Button>
                          </GridItem>
                      </GridContainer>
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center", height : '45px'}}>
                            <Button style={{fontFamily: 'Montserrat, sans-serif', backgroundColor: '#0aed0a', borderRadius: '20px', color: '#FFF', border: '1px solid #787878', marginTop: '-7px'}} fullWidth onClick={this.cadastrarSe}>CADASTRAR-SE</Button>
                          </GridItem>
                      </GridContainer>                      
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"right"}}>
                            <p style={{fontFamily: 'Montserrat, sans-serif', textAlign: 'center', color: '#787878'}}><Link style={{color: '#787878'}} to={"/esqueceuSenha"} >Esqueceu a senha?</Link></p>
                          </GridItem>
                      </GridContainer>
                    </CardLoginBody>
                  </CardLogin>
                </GridItem>
              </GridContainer>
              <Snackbar
                open={this.state.openSnack}
                onClick={this.handleSnackClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.mensagemSnack}</span>}
              />
              </>
          // </BlockUi>
        );
      }      
}