import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "components/Grid/GridItem.js";
import QuantityButtons from "components/CustomButtonGroup/QuantityButtons";

const BlueCheckbox = withStyles({
  root: {
    color: "#03A9F4",
    padding: "0 7px 0 0",
    "&$checked": {
      color: "#03A9F4"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const styles = {
  cardCategory: {
    fontFamily: "Montserrat, sans-serif",
    color: "#999494"
  },
  cardTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500
  },
  borderCard: {
    borderTop: "5px solid #03A9F4!important"
  },
  precoPE: {
    fontFamily: "Montserrat, sans-serif",
    marginBottom: 0,
    textAlign: "right",
    fontSize: "17px",
    color: "#03A9F4"
  }
};

const useStyles = makeStyles(styles);

const CardPlano = props => {
  const { prod, checkPE, changeQuant, changeCheck } = props;
  const classes = useStyles();
  const [isCheck, setIsCheck] = useState(false);

  function formataValor(valor) {
    return parseFloat(valor).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  const handleCheck = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
    changeCheck(isChecked, prod.Guid);
  };

  return (
    <GridItem xs={12} sm={12} md={4} key={prod.Guid}>
      <Card className={classes.borderCard}>
        <CardBody>
          <h4 className={classes.cardTitle}>
            <BlueCheckbox
              checked={checkPE.get(prod.Guid)}
              value={prod.Guid}
              name={prod.Guid}
              onChange={handleCheck}
            />
            {prod.NomeProduto}
          </h4>
          <p className={classes.cardCategory}>{prod.DescricaoProduto}</p>
          {isCheck ? (
            <QuantityButtons change={changeQuant} id={prod.Guid} />
          ) : (
            ""
          )}
          <p className={classes.precoPE}>{formataValor(prod.ValorProduto)}</p>
        </CardBody>
        <CardFooter chart></CardFooter>
      </Card>
    </GridItem>
  );
};

CardPlano.propTypes = {
  prod: PropTypes.object.isRequired,
  checkPE: PropTypes.object.isRequired,
  changeQuant: PropTypes.func.isRequired,
  changeCheck: PropTypes.func.isRequired
};

export default CardPlano;
