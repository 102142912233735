import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  //const [open, setOpen] = React.useState(pops.openConfirmacao);

  return (
    <div>
      <Dialog
        open={props.openConfirmacao}
        onClose={props.setCloseConfirmacao}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmação"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.mensagem}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.setCloseConfirmacao} color="primary">
            NÃO
          </Button>
          <Button onClick={props.handleSim} color="primary" autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
