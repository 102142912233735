import React from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Table from "components/Table/Table.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import {
  myFormView
} from "assets/jss/material-dashboard-react.js";
import moment from "moment";

const myFormViewStyle = makeStyles(myFormView);

 function ViewNotificacao(props) {
  
    const classesFormView = myFormViewStyle();
    
  return (
      <>
      <GridContainer>
          <GridItem lg={2}>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card>            
              <CardHeader color="info">
                <h4 className={classesFormView.cardTitleWhite}>{props.notificacao.Assunto}</h4>
                <p className={classesFormView.cardCategoryWhite}>{moment(props.notificacao.DataCriacao).format("DD/MM/YYYY HH:mm")}</p>
              </CardHeader>
              <CardAvatar profile style={{marginTop: "12px", textAlign: 'center', width: '60px'}}>
                <Icon style={{fontSize: "50px"}}>notification_important</Icon>
              </CardAvatar>
              <CardBody>
                <GridContainer>
                  <GridItem>
                      {props.notificacao.Descricao}
                  </GridItem>
                </GridContainer>                
              </CardBody>
            </Card>
          </GridItem>
      </GridContainer>
    </>
    );
}
export default ViewNotificacao;