import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage1 from "assets/img/sidebar-1.jpg";
import bgImage2 from "assets/img/sidebar-2.jpg";
import bgImage3 from "assets/img/sidebar-3.jpg";
import bgImage4 from "assets/img/sidebar-4.jpg";
import logo from "assets/img/condutor-logo.png";
//import LoadingFull from "components/Loading/LoadingFull";
import checaLogin from 'services/checaLogin';
//import checaPlanoVigencia from 'services/checaPlanoVigencia';
import SnackbarNotification from 'components/Uteis/SnackbarNotification';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import GA from 'services/GoogleAnalytics'

// Loader and block
import 'loaders.css/loaders.min.css';
import 'react-block-ui/style.css';


let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/app") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} name='Condutor Legal' />}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/app" to="/app/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({...rest }) {
  checaLogin(rest);
  GA.pageview(window.location.pathname);
  //checaPlanoVigencia(rest);
  const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4];
  
  // styles
  const classes = useStyles();
  
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  // states and functions
  const [image] = React.useState(bgImages[2]);
  //const [image] = React.useState(bgImages[Math.floor(Math.random() * 4)]);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [snackPush, setSnackPush] = React.useState({ open: false, mensagem: '' });
  const [nomeUsuario, setNomeUsuario] = React.useState("Legal");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/dashboard";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  React.useEffect(() => {
    
    let intervalPushApp = setInterval(() => {
      //console.log('verificarNotificacaoPushApp');
      verificarNotificacaoPushApp();
    }, 60000);
    return function clearIntervalApp(){
      //console.log('Limpar verificarNotificacaoPushApp');
      clearInterval(intervalPushApp);
      //clearInterval(intervalPushAppClose);
    }
  });

  React.useEffect(() => {
    api.get(serverApi.url + serverApi.versao + '/Usuario/Perfil').then(response => {
      if(response.data !== null) {
        let nomeServer = response.data.Nome;
        let nome = nomeServer.split(" ")[0];
        setNomeUsuario(nome);
      }
    })
    .catch(error => {
      console.error(error);
    })
  })

  function verificarNotificacaoPushApp() {
    api.get(serverApi.url + serverApi.versao + '/Notificacao/PushApp').then(response => {
      console.log(response);
      if(response.data !== null) {
        setSnackPush({ ...rest, open: true, mensagem : response.data.Mensagem});
        setTimeout(() => {
          //console.log('Fechar Snack');
          setSnackPush({ ...rest, open: false, mensagem : ''});
        }, 10000);
      }
    })
    .catch(error => {
      console.error(error);
    })
  }
  
  return (
    <>
      <SnackbarNotification showAlert={snackPush.open} mensagem={snackPush.mensagem} />
      <div className={classes.wrapper} style={{backgroundColor: '#ffffff'}}>
        <Sidebar
          routes={routes}
          logoText={`Condutor ${nomeUsuario}`}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {getRoute() ? <Footer /> : null}
        </div>
      </div>
    </>
  );
}
