import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

  
function MySnackbarErro(props) {
  
    return (
        <Snackbar
                open={props.IsErro}
                onClick={props.HandleSnackClose}
                ContentProps={{
                  'aria-describedby': 'snackbar-fab-message-id',
                  style:{backgroundColor: 'red', textAlign: 'center'}
                }}
                message={<span id="message-id">{props.Mensagem}</span>}
              />
    );
}
export default MySnackbarErro;