import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import SemRegistro from "components/Uteis/SemRegistro.js";
import GridMulta from "components/Grid/GridMulta.js";
import {msg} from 'services/msg.js';
import {
  myFormView
} from "assets/jss/material-dashboard-react.js";
import moment from "moment";

const styles = {
    cardModelo: {
      color: "rgba(0,0,0,.62)",
      margin: "0",
      fontSize: "18px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
    cardPlaca: {
      color: "rgba(3,169,244,.62)",
      margin: "0",
      fontSize: "30px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
  };

const useStyles = makeStyles(styles);

const myFormViewStyle = makeStyles(myFormView);
let rows = [];

 function ViewVeiculo(props) {
    
    const classes = useStyles();
    const classesFormView = myFormViewStyle();
    rows = [];
    props.veiculo.Multas.map((multa) => {

        rows.push([moment(multa.DataInfracao).format("DD/MM/YYYY"), multa.AutoRenainf, multa.LocalInfracao, multa.ValorSerPago]);
    });

  return (
    <>
    <div style={{display: (props.veiculo.QtdeConsultas <= 0 ? '' : 'none')}}>
        <GridContainer>
            <GridItem lg={2}></GridItem>
            <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card>
            <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{fontWeight: 'bold', textAlign: 'center'}}>
                    Fique tranquilo!!!
                    <br />Estamos realizando as buscas junto aos órgãos competentes. Se você não for notificado em 48 horas, entre em contato conosco através do e-mail atendimento@condutorlegal.com informando o número do renavan e placa cadastrado.
                </GridItem>
                </GridContainer>
            <br />
            </Card>
            </GridItem>
        </GridContainer>
      </div>
      <div style={{display: (props.veiculo.ComErro ? '' : 'none')}}>
        <GridContainer>
          <GridItem lg={2}></GridItem>
            <GridItem xs={12} sm={12} md={12} lg={8}>
              <Card>
              <CardHeader color="danger">
                <div className={classesFormView.cardTitleWhite}>Veículo com problemas</div>
                <p className={classesFormView.cardCategoryWhite}>Verifique a mensagem abaixo e realize o acerto</p>
              </CardHeader>
              <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{fontWeight: 'bold', textAlign: 'center', width: '100px', height: '50px'}}>
                      {props.veiculo.MensagemErro}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
                  <Link to={"../corrigircadastro/" + props.veiculo.Id}><Button color="info" size="md">Corrigir Veículo</Button></Link>
                  </GridItem>
                </GridContainer>
              <br />
              </Card>
            </GridItem>
            </GridContainer>
          </div>
      <GridContainer>
      <GridItem lg={2}></GridItem>
        <GridItem xs={12} sm={12} md={12} lg={8}>
        <Card>
            <CardBody profile>
                <h6 className={classes.cardModelo}>{props.veiculo.Modelo}</h6>
                <h4 className={classes.cardPlaca}>{props.veiculo.Placa}</h4>
            </CardBody>           
            <CardBody>
                <div style={{display: (props.veiculo.AnoUltimoLicenciamento ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={9} sm={10} md={10}>
                            <div className={classesFormView.box}>
                                <div>Ano Último Licenciamento</div>
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={2} md={2} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.AnoUltimoLicenciamento}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Proprietario ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={6} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Proprietário</div>
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Proprietario}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.NumeroRenavam ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={5} md={5}>
                            <div className={classesFormView.box}>
                                <div>Renavam</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.NumeroRenavam}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Modelo ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={4} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Modelo</div>
                            </div>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Modelo}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Fabricante ? '' : 'none')}}>
                    <GridContainer style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={4} >
                            <div className={classesFormView.box}>
                                <div>Fabricante</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Fabricante}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Chassi ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Chassi</div>
                            </div>
                        </GridItem>
                        <GridItem xs={9} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Chassi}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.LocalRegistro ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={7} sm={5} md={5}>
                            <div className={classesFormView.box}>
                                <div>Local Registro</div>
                            </div>
                        </GridItem>
                        <GridItem xs={5} sm={7} md={7} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.LocalRegistro}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.AnoFabricacao ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={8} sm={8} md={8}>
                            <div className={classesFormView.box}>
                                <div>Fabricação</div>
                            </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.AnoFabricacao}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.AnoModelo ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Ano Modelo</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.AnoModelo}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Cor ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Cor</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Cor}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.EspecieTipo ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Tipo</div>
                            </div>
                        </GridItem>
                        <GridItem xs={9} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.EspecieTipo}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.Combustivel ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Combustivel</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.Combustivel}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div style={{display: (props.veiculo.CapacidadePotenciaCilindrada ? '' : 'none')}}>
                    <GridContainer  style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={4}>
                            <div className={classesFormView.box}>
                                <div>Cap/Pot/Cil</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={8} >
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxLabel}>{props.veiculo.CapacidadePotenciaCilindrada}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
              {/* <GridContainer >
                <GridItem xs={12} sm={12} md={12} >
                <Button color="danger">Desativar Veículo</Button>
                </GridItem>
              </GridContainer> */}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
        <div style={{display: (props.veiculo.Debitos.length > 0 ? '' : 'none')}}>
        <Card>            
            <CardHeader color="info">
              <h4 className={classesFormView.cardTitleWhite}>Débitos</h4>
              <p className={classesFormView.cardCategoryWhite}>Pagamentos que estão abertos</p>
            </CardHeader>
            <CardBody>
                <SemRegistro Exibe={props.veiculo.Debitos.length <= 0} Texto={msg.semInformacaoSobreDebitoVeiculo} />
                
                {props.veiculo.Debitos.map((debito, index) => {
                return ( 
                    <>
                    <GridContainer style={myFormView.gridContainer}>
                        <GridItem xs={5} sm={4} md={6} lg={4}>
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxLabel}>{debito.Descricao}</div>
                            </div>
                        </GridItem>
                        <GridItem xs={7} sm={8} md={6}  lg={8}>
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{debito.Valor}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </>
                )})}
              
            </CardBody>
          </Card>
          </div>
          
        <div style={{display: (props.veiculo.Dicas.length > 0 ? '' : 'none')}}>
          <Card>            
            <CardHeader color="info">
              <h4 className={classesFormView.cardTitleWhite}>É importante saber!</h4>
              <p className={classesFormView.cardCategoryWhite}>Algumas dicas para você ser um Condutor Legal</p>
            </CardHeader>
            <CardBody>
            <SemRegistro Exibe={props.veiculo.Dicas.length <= 0} Texto={'Não se preocupe, estamos buscando algumas dicas importantes para você. '} />
                            {props.veiculo.Dicas.map((dica, index) => {
                return ( 
                    <>
                    <GridContainer style={myFormView.gridContainer}>
                        <GridItem xs={7} sm={8} md={6}  lg={8}>
                            <div className={classesFormView.box}>
                                <div className={classesFormView.boxValue + ' ' + classesFormView.boxMarginBottom}>{dica}</div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </>
                )})}              
            </CardBody>
          </Card>
          </div>
        </GridItem>
      </GridContainer>
        <div style={{display: (props.veiculo.Multas.length > 0 ? '' : 'none')}}>
        <GridContainer>
        
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                <SemRegistro Exibe={rows.length <= 0} Texto={'Esse veículo não possui informações de multas'} />
                    <GridMulta rota={'../../multas/view/'} display={true} multas={props.veiculo.Multas} />
                </Card>
            </GridItem>
        </GridContainer>
      </div>
    </>
    );
}
export default ViewVeiculo;