import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "components/Grid/GridItem.js";
import Warning from "components/Typography/Warning.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);


 function Painel(props) {
  
    const classes = useStyles();

  return (
      <GridItem xs={12} sm={6} md={6} lg={4} key={props.bloco.Id}>
        <Link to={props.bloco.Rota} >
          <Card className={classes.card}>
            <CardHeader color={props.bloco.Color === null ? "warning" : props.bloco.Color} stats icon>
              <CardIcon color={props.bloco.Color === null ? "warning" : props.bloco.Color} className={classes.boxIcon}>
                <Icon>{props.bloco.Icone === null ? "notification_important" : props.bloco.Icone}</Icon>
              </CardIcon>
              <div className={classes.cardTitle}>{props.bloco.Titulo}</div>
              <div className={classes.cardMensagem}>
                {props.bloco.Mensagem}
              </div>
            </CardHeader>
            <Hidden smDown>
              <CardFooter stats>
                  <div className={classes.cardBottom}>
                    <Warning>
                      <Icon>{props.bloco.IconeRodape === null ? "arrow_forward_ios" : props.bloco.IconeRodape}</Icon>
                    </Warning>
                      <div>{props.bloco.Rodape}</div>
                  </div>
              </CardFooter>
            </Hidden>
          </Card>
        </Link>
      </GridItem>
    
    );
}
export default Painel;