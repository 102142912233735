import React from 'react';

export default function ViewTermosECondicoes() {
   return(
       <>
        Leia com atenção os termos e condições. Ao se cadastrar na Plataforma Condutor Legal. ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS do Website e do Aplicativo.
 <br/> <br/>
O acesso e  uso da Plataforma denominada  Condutor Legal (a seguir, a "Plataforma") é  oferecido  pela empresa CONDUTOR LEGAL PRESTADORA DE SERVICOS LTDA, CNPJ 35.447.156/0001-08, endereço Av. das Américas, 3434 Bloco 4, 3º andar sala 305, CEP: 22.640-102, Rio de Janeiro, RJ. <br/><br/>


<b>1. PLATAFORMA</b><br/><br/>

1.1 A plataforma é configurada como uma plataforma tecnológica, voltada para dispositivos móveis e não móveis,  cujo  objetivo  é  oferecer  aos  usuários  acesso  e,  quando  apropriado,  serviço  de  venda,  de  vários pacotes de serviços de hospedagem e/ou de transporte.<br/><br/>

1.2 Para acessar a Plataforma, é  necessária  a utilização  de um  computador, dispositivo móvel e / ou um tablet, com as especificações e as configurações técnicas necessárias para o seu bom funcionamento, além da  necessidade  de  conexão  com  a  Internet.  As  despesas  relacionadas  à  conexão  serão  da  exclusiva responsabilidade  do  Usuário.  Se  for  necessário  interromper  ou  modificar  os  Serviços  de  Tecnologia  da Plataforma,     Condutor Legal   se   reserva   o   direito   de   fazê-lo   sem   aviso   prévio   e   sem   assumir   qualquer responsabilidade para usuários ou terceiros.<br/><br/>

1.3 O USUÁRIO fica desde logo informado e concorda que a opção de aquisição de serviços por meio desta plataforma estará disponível 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, mas que por motivos alheios à vontade da Condutor Legal, incluindo mas não se limitando à caso fortuito, força maior, e manutenção dos servidores, os mesmos poderão estar indisponíveis para consultas pelo USUÁRIO.<br/><br/>

<b>2. CONDIÇÕES DE USO DA PLATAFORMA</b><br/><br/>

2.1 O acesso à Plataforma será limitado a pessoas com mais de 18 (dezoito) anos de idade.   Sendo que o acesso a determinado Conteúdo e / ou participação em certas promoções oferecidas pela Condutor Legal pode ser limitado a adultos ou menores emancipados, conforme previsto nas condições especiais correspondentes estabelecidas para esse fim.<br/><br/>

2.2 Através da Plataforma, a Condutor Legal fornece ao Usuário acesso a uma variedade de conteúdo, informações e dados em relação aos Planos (doravante denominado "Conteúdo") e se reserva  ao  direito  de  modificar,  a  qualquer  momento,  a  apresentação,  configuração  e  localização  das diferentes seções da Plataforma, bem como seus Conteúdos.<br/><br/>

2.3 O USUÁRIO, para utilizar os serviços acima descritos, deverá ter capacidade  jurídica para atos civis e deverá,  necessariamente,  prestar  as  informações  exigidas  no  CADASTRO,  assumindo  integralmente  a responsabilidade  pela  exatidão  e  veracidade  das  informações  fornecidas  durante  o  preenchimento  do formulário de inscrição on-line ou qualquer outra rede social habilitada pela Condutor Legal para esta finalidade (doravante denominado a "conta de usuário"). Sendo que a conta de usuário poderá ser verificada a qualquer momento pela Condutor Legal que terá acesso a todas as informações e dados nela contidos.<br/><br/>

2.4 Em caso de informações incorretas, inverídicas ou não confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar documentação que comprove a correção, A Condutor Legal se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os serviços on-line dos Planos e/ou App Condutor Legale, até que, a critério da Condutor Legal   a situação de divergência nos dados cadastrais esteja regularizada.<br/><br/>

2.5 Efetuado com sucesso o CADASTRO, o USUÁRIO terá acesso aos serviços por meio de login/e-mail e senha (doravante  denominado  "Dados  de  Acesso"),  dados  esses  que  são  pessoais,  intransferíveis,  que  serão utilizados a partir da criação da Conta de Usuário para o acesso à plataforma e aos Planose/ou App Condutor Legal e se compromete a não divulgá-los a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso de login/e-mail e senha de sua titularidade.<br/><br/>

2.6  O  Usuário  garante,  sob  sua  própria  responsabilidade,  a  precisão  e  a  veracidade  das  informações fornecidas  à  Plataforma,  que  devem  ser  atualizadas  em  todos os  momentos,  eximindo  expressamente  a Condutor Legal das consequências e / ou danos que a inexatidão e falsidade do mesmo possam causar à Eventoss e a terceiros.<br/><br/>

2.7 O usuário é expressamente proibido aos comportamentos identificados a seguir:<br/><br/>

2.7.1 Utilizar a plataforma de maneira contrária aos princípios estabelecidos nestes Termos e Condições, de forma não autorizada, assim como contrária ao disposto na Legislação Vigente. Causar danos à plataforma ou aos sistemas de seus fornecedores ou a terceiros.<br/><br/>

2.7.2 Cadastrar-se e / ou criar identidades falsas da Plataforma, atuar em nome de terceiros sem poder ou legitimação suficiente e / ou copiar a identidade de outros por meio de perfis falsos, respondendo pelas implicações criminais aplicáveis.<br/><br/>

<b>3.OBRIGAÇÕES DA CONDUTOR LEGAL</b><br/><br/>

3.1  A Condutor Legal  se  responsabiliza  em  disponibilizar  no  site  espaço  virtual  que  permita  ao  USUÁRIO devidamente cadastrado efetivar pedidos de compra de Plano e/ou App Condutor Legal anunciados e comercializados e, também, disponibilizar ao USUÁRIO meios de pagamento dos produtos on-line.<br/><br/>

3.2 Proteger,  por meio de armazenamento  em servidores ou quaisquer outros meios magnéticos de  alta segurança, a confidencialidade de todas as informações e cadastros relativos aos USUÁRIOS. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.<br/><br/>

3.3  A  Condutor Legal  poderá  fornecer  as  informações  do  USUÁRIO  para  terceiros  nos  seguintes  casos:  I  -  por determinação Judicial; II - em atendimento a requerimento do Ministério Público ou Policial; III - para garantir a não violação de direitos de terceiros; IV  - para garantir a observância pelos USUÁRIOS deste Termo de Adesão, Uso e Condições.<br/><br/>

3.4 De acordo com as disposições dos regulamentos de hospedagem e transporte atuais, a Condutor Legal reserva- se ao direito de executar o serviço de vendas de planos e do App, através de empresas colaboradoras, sem que isso represente ônus ao consumidor usuário.<br/><br/>

3.4.1 A Condutor Legal compromete-se a exigir a conformidade dos serviços de vendas de planos e do App de acordo com a legislação vigente.<br/><br/>

3.5   A   provisão   final   do   serviço   vendas de planos e do App contratado   através   da   plataforma   é responsabilidade da Condutor Legal. No entanto, a Condutor Legal não será responsável por quaisquer violações que não sejam  diretamente  imputáveis  a ela, nem  por causa de  circunstâncias  imprevisíveis,  força maior ou para cumprir requisitos legais ou administrativos.<br/><br/>

<b>4. CONDIÇÕES DE PAGAMENTO E COBRANÇA</b><br/><br/>

4.1 O pagamento por cartão de crédito e boleto são as únicas forma de pagamento aceita na Plataforma;<br/><br/>

4.2 O preço do pacote de planos ou do App em questão será apresentado ao Usuário através da
Plataforma, antes do processo de pagamento, para sua efetiva confirmação e validação;<br/><br/>

4.3 O Usuário deve acessar ou, quando apropriado, ser redirecionado para o site do referido provedor de pagamento através do link fornecido para este fim no Formulário de Registro;<br/><br/>

4.4 Referente a vendas de planos ou do App, o valor do pacote é individual, intransferível ;<br/><br/>



<b>5. POLÍTICA DE CANCELAMENTO</b><br/><br/>

5.1  O reembolso será concedido  quando  a solicitação  for realizada dentro  de 7  dias  a contar da data da compra e 72h antes da data do check-in.<br/><br/>

5.3 Em caso de cancelamento o cliente deverá enviar um e-mail para financeiro@condutorlegal.com  com a cópia do comprovante de pagamento.<br/><br/>


6.7 Não será permitida alteração de datas/horário de check-in e checkout após a confirmação da compra.<br/><br/>

6.8 À Pessoa com Deficiência solicitamos o envio de e-mail para contato@eventoss.com.br com informações que permitam um melhor atendimento até 27/03/2020.<br/><br/>

6.9 É absolutamente proibido fumar no interior do hotel (salvo nos devidamente identificados como espaços destinados a fumantes).<br/><br/>

6.10 Não é permitida a hospedagem de animais;<br/><br/>

6.11  Exclusivamente  para o  hospedagem  de  cães  de  assistência,  a  Eventoss   disponibiliza  um  e-mail  aos Usuários para a consulta prévia de disponibilidade, de acordo com o hotel parceiro, data  e horário de check- in   e/ou   checkout:   contato@eventoss.com.br.   No   check-in   deverá   ser   apresentada   a   plaqueta   de identificação do animal que deverá estar acompanhado da pessoa com deficiência, mantido com a guia e sob controle.<br/><br/>


<b>8. CANAL DE COMUNICAÇÃO</b><br/><br/>

8.1  Para  estabelecer  contato  entre  A  Condutor Legal  e  o  USUÁRIO  fica  disponibilizado  o  endereço  eletrônico, atendimento@condutorlegal.com, sendo certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro endereço eletrônico atual por intermédio do qual se farão as comunicações a ele dirigidas.<br/><br/>

<b>9. ACEITAÇÃO DESTES TERMOS DE USO E CONDIÇÕES</b><br/><br/>
9.1 O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e obrigações estabelecidas no presente TERMO.<br/><br/>

9.2 Estes Termos e Condições podem ser modificados pela Condutor Legal a qualquer momento sem aviso prévio, quando considerado oportuno ou necessário para otimizar e / ou adaptar a regulamentação de acesso e uso da Plataforma ou à legislação vigente. Uma vez que a modificação acima mencionada tenha sido introduzida, se  o  Usuário  continuar  acessando  e  usando  as  diferentes  ferramentas da Plataforma, entenderá que  ele aceita as referidas modificações.
       </>
   );
}