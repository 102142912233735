import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    maxWidth: "100%",
    overflowScrolling: "touch",
    overflowX: "hidden"
  },
  content: {
    marginTop: "50px",
    padding: "5px 5px",
    maxWidth: "99.1%",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "30px"
  },
  iconLoader: {
    marginLeft: "49%"
  }
});

export default appStyle;
