import React from "react";
// core components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function ViewRecurso(props) {
  const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });


  return (
    <>
      <div style={{ paddingTop: "35px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Auto de Infração</TableCell>
                <TableCell align="center">Tipo Defesa</TableCell>
                <TableCell align="center">Estágio</TableCell>
                <TableCell align="center">Protocolo</TableCell>
                <TableCell align="center">Placa</TableCell>
                <TableCell align="center">Renavam</TableCell>
                <TableCell align="center">Data Atualização</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.recursos.map((rec, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {rec.AutoDeInfracao}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.TipoDefesa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Estagio}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Protocolo ?? "-"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Placa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Renavam}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.DataUltimaMovimentacao
                        ? formatDate(rec.DataUltimaMovimentacao)
                        : formatDate(rec.DataSolicitacaoRecurso)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default ViewRecurso;
