import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  quantCont: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    width: "20%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  quantButton: {
    "&:focus": {
      outline: "none"
    },
    border: "none",
    width: "30%"
  }
};

const useStyles = makeStyles(styles);

const QuantityButtons = props => {
  const { change, id } = props;
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    change(id, quantity);
  }, [quantity]);

  const changeQuantity = e => {
    const op = e.target.innerText;
    if (op === "-") {
      if (quantity > 1) setQuantity(quantity - 1);
    } else {
      if (quantity <= 99) setQuantity(quantity + 1);
    }
  };

  return (
    <div className={classes.quantCont}>
      <button className={classes.quantButton} onClick={changeQuantity}>
        -
      </button>
      <span>{quantity}</span>
      <button className={classes.quantButton} onClick={changeQuantity}>
        +
      </button>
    </div>
  );
};

QuantityButtons.propTypes = {
  change: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default QuantityButtons;
