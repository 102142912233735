import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Icon } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  inline: {
    display: 'inline'
  },
  iconColor: {
    color: "#FFF"
  },
  boxPontosDK: {
    position: 'absolute',
    bottom: '15px',
    left: '2px',
    color: '#737495'
  },
  boxPontosMB: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    top: '30px',
    color: '#737495'
  }
}));

 function GridCondutor(props) {
  
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)'); 
  
  return (
    <div style={{display: (props.display ? '' : 'none')}}>
    <List className={classes.root}>      
      {props.condutores.map((condutor) => {
        return ( 
          <React.Fragment key={condutor}>
            <Link to={condutor.Guid === null ? 'condutores/cadastrar' : "condutores/view/" + condutor.Guid} >
              <ListItem alignItems="flex-start" style={{position: 'relative'}}>
                <ListItemAvatar>
                  <div className={matches ? classes.boxPontosMB : classes.boxPontosDK}>
                      <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px'}}>{condutor.TotalPontos}</div>
                      <div style={{textAlign: 'center', fontSize: '14px'}}>{condutor.TotalPontos > 0 ? 'Pontos' : 'Ponto'}</div>
                      <p style={{marginBottom: '0px', fontSize: '14px'}}>12 meses</p>
                    </div>
                    <Hidden xsDown>
                      <div style={{position: 'absolute', bottom: '40px',left: '75px', color: '#737495'}}>
                        <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px'}}>{condutor.QtdeConsultas}</div>
                        <div style={{textAlign: 'center', fontSize: '14px'}}>{condutor.QtdeConsultas > 0 ? 'Consultas' : 'Consulta'}</div>
                      </div>
                    </Hidden>
                </ListItemAvatar>
                <ListItemText
                  style={{marginLeft: matches ? '0px' : '78px'}}
                  primary={<React.Fragment>
                    <Typography
                        component="span"
                        className={classes.titulo}
                        color="textPrimary"
                      >
                    {(condutor.Nome !== '' ? condutor.Nome : condutor.NumeroCnh)}
                    </Typography>
                    </React.Fragment>}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {(condutor.Nome !== '' ? 'CNH: ' + condutor.NumeroCnh : 'CPF: ' + condutor.Cpf)}&nbsp;<br />
                        {(condutor.Nome !== '' ? 'CPF: ' + condutor.Cpf : '')}
                      </Typography>&nbsp;<br />
                      { condutor.Chips.map((chip) => {
                                return (<><Chip icon={chip.Style.icon ? <Icon className={classes.iconColor}>{chip.Style.icon}</Icon> : ""} label={chip.Label} style={{backgroundColor : chip.Style.backgroundColor, color : chip.Style.color}} />&nbsp;</>);
                              }) }
                    </React.Fragment>
                  }
                />
              </ListItem>
              </Link>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>
    </div>
    );
}
export default GridCondutor;