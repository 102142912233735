import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import bgImage1 from "assets/img/background-login-1.jpeg";
import bgImage2 from "assets/img/background-login-1.jpeg";
import bgImage3 from "assets/img/background-login-1.jpeg";
import bgImage4 from "assets/img/background-login-1.jpeg";
import bgImageLogo from "assets/img/logo-login.png";
import shareIconSafari from "assets/img/icon-share-safari.png";
import TextField from '@material-ui/core/TextField';

import {serverApi} from 'services/constante.js';
import LoadingDefault from "components/Loading/LoadingDefault";
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import 'react-block-ui/style.css';
import './styleSnackbar.css'
import GA from 'services/GoogleAnalytics'

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4];
const imagem = bgImages[Math.floor(Math.random() * bgImages.length)];


/* let deferredPrompt;
let jaInstalado = true;
let eIos = false;
window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
});
// DETECTA SE DISPOSITIVO É IOS
function isIos() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}
if(isIos()) {
  eIos = true;
}
//DETECTA SE APLICATIVO JÁ FOI INSTALADO
if (window.matchMedia('(display-mode: standalone)').matches) {
  jaInstalado = false;
} */



/* function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
} */

/* const btnSnackInstall = {
  backgroundColor: 'transparent',
  boxShadow: 'none'
}; */

export default class Login extends Component {
 
  constructor(props) {
    super(props);
    
    this.state  = {
      email: localStorage.getItem('CLLogin'),
      password: localStorage.getItem('CLSenha'),
      blocking: false,
      openSnack: false,
      //openSnackInstallApp: jaInstalado
    };
    
    if(localStorage.getItem('CLToken') !== null) {
      this.props.history.push('/app/inicial');
    }
    GA.pageview(window.location.pathname);
  } 
  
  
  acessar = async () => {
    
    if(!this.validateForm() ) {
      alert('Informe o e-mail e senha!');
      return;
    }
    let THIS = this;
    setTimeout(() => {
    //  THIS.setState({blocking: false});
    }, 2000);
    THIS.setState({blocking: true});
    //console.log('O link foi clicado.');

    var querystring = require('querystring');
    const data = querystring.stringify({ username: this.state.email, password: this.state.password, grant_type: 'password' })

    axios.post(serverApi.url + '/token', data).then(response => {
      // handle success
      localStorage.setItem('CLToken', response.data.access_token);
      localStorage.setItem('CLLogin', THIS.state.email);
      localStorage.setItem('CLSenha', THIS.state.password);

      /*GA.event({
        'category': 'Login',
        'action': 'App',
        'label': THIS.state.email
      });*/

      THIS.props.history.push('/app/inicial');

      //console.log(response.data);
    })
    .catch(error => {
      // handle error
      console.error(error);
      try{
        switch(error.response.data.error_description)
        {
          case 'USUARIO_SENHA_INVALIDO':
            alert('Usuário ou senha inválidos!');
          break;
          case 'USUARIO_BLOQUEADO':
              alert('Usuário bloqueado!\nEnvie um e-mail para: sac@condutorlegal.com');
          break;
          case 'USUARIO_NAO_EXISTE':
              alert('Usuário não cadastrado em nossa base de dados!\nClique no link: Cadastre-se');
          break;
          default:
              alert('Náo foi possível realizar a autenticação!\nTente novamente!');
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar a autenticação!\nTente novamente!');

      }
    })
    .finally( ()=> {
      this.setState({blocking: false});
    });

   };

  validateForm() {
    if(this.state.email === null || this.state.password === null)
    {
      return false;
    }
    return this.state.email.length > 0 && this.state.password.length > 0;
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  handleSnackClose = () => {
    this.setState({
      openSnack: false
    });
  };
  
 /*  handleClickA() {
    deferredPrompt.prompt();
    deferredPrompt.userChoice
    .then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt', choice);
      } else {
        console.log('User dismissed the A2HS prompt', choice);
      }
      deferredPrompt = null;
    });
  } */

 /*  handleCloseInstallApp = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openSnackInstallApp: false
    });
  }; */

  render() {
        return (
            // <BlockUi tag="div" blocking={this.state.blocking} style={{height: "1000px", overflow: 'none'}}>
          <>
          <div  style={{paddingTop: "15%", backgroundImage: "url(" + imagem + ")", 
          position: "fixed",
          zIndex: "0",
          height: "100%",
          width: "100%",
         // display: "block",
          top: "0",
          left: "0",
          backgroundSize: "cover",
          backgroundPosition: "center center", "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            backgroundColor: '#cccccc',
            opacity: ".8"
          } }}>
          </div>
          <LoadingDefault display={this.state.blocking} />
          {/* {!eIos ? ( <Snackbar
            key={1}
            open={this.state.openSnackInstallApp}
            onClose={this.handleCloseInstallApp}
            message="Clique aqui para criar um atalho"
            className="snackbar-android"
            action={
              <React.Fragment>
                <Button color="primary" size="small" style={btnSnackInstall} onClick={this.handleClickA}>
                  criar
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseInstallApp}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          /> ) : (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.openSnackInstallApp}
            onClose={this.handleCloseInstallApp}
            message={<p style={{marginBottom: 0}}>Crie um atalho para este app clicando no ícone <img src={shareIconSafari} alt="Ícone botão de compartilhamento safari" style={{marginLeft: '2px', marginRight: '2px'}} /> depois em "Adicionar à Tela Inicial"</p>}
            className="snackbar-ios"
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseInstallApp}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />)} */}
          <br />
              <GridContainer>
                <GridItem xs={1} sm={2} md={3} lg={4}>
                    </GridItem>
                  <GridItem xs={10} sm={8} md={6} lg={4}>
                  <Card>
                    <CardAvatar profile>                      
                      <img src={bgImageLogo} alt="..." style={{width: '80px', margin: "10px"}} />                      
                    </CardAvatar>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>                            
                          <p style={{ textAlign: 'center', fontSize: 17, fontWeight: 'bold', marginBottom: 2 }}>Condutor Legal</p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>                            
                          <p style={{ textAlign: 'center', fontSize: 10, fontWeight: 300, marginBottom: 2 }}>Monitoramento de multas e pontos da cnh</p>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>                            
                            <TextField
                              style={{marginTop : '25px'}}
                              id="email"
                              label="E-mail"
                              margin="dense"
                              onChange={this.handleChange}
                              fullWidth
                              inputProps={{ maxLength: 150 }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <TextField
                              style={{marginTop : '25px'}}
                            id="password"
                            label="Senha"
                            margin="dense"
                            type="password"
                            onChange={this.handleChange}
                            fullWidth
                            inputProps={{ maxLength: 12 }}
                          />
                          </GridItem>
                      </GridContainer>
                      <br />
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center", height : '65px'}}>
                          <Button fullWidth color="info" onClick={this.acessar}>Acessar</Button>                       
                          </GridItem>
                      </GridContainer>
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center", height : '45px'}}>
                          <Link to={"/cadastrarUsuario"} >
                              Cadastre-se
                            </Link>
                          </GridItem>
                      </GridContainer>                      
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"right"}}>
                          <Link to={"/esqueceuSenha"} >Esqueceu a senha?</Link>                        
                          </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <Snackbar
                open={this.state.openSnack}
                onClick={this.handleSnackClose}
                //TransitionComponent={state.Transition}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.mensagemSnack}</span>}
              />
              </>
          // </BlockUi>
        );
      }      
}