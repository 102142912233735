import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GridMulta from "components/Grid/GridMulta.js";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';
import Button from 'components/CustomButtons/Button';
 
export default class Multas extends React.Component {
  
  constructor(props) {
    super(props);
    
    checaLiberacaoMenu(props);
    
    this.state  = {
      multas: [],
      loadingGrid: true,
      isErro: false,
      totalVeiculos: 0,
      usuarioNome: '',
    };
  } 

  componentDidMount(){
    this.carregar();
  }

  carregar = () => {
    this.setState({isErro: false});
    api.get(serverApi.url + serverApi.versao + '/Multa/Multas').then(response => {
      this.setState({
        multas: response.data
      });
      
      //console.log("resposta mutas", response.data);
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true}); 
    })
    .finally(() => {   
      this.setState({
        loadingGrid: false
      });   
    });

    //VERIFICA SE TEM VEÍCULOS
    this.setState({loadingGrid: true});
    var formdata = new FormData();
    let email_user = localStorage.getItem("CLLogin");
    formdata.append('function', 'get_veiculos_usuario');
    formdata.append('email', email_user);

    api.post(serverApi.urlPHP + '/apicdl/usuario.php', formdata).then(response => {
      //console.log("resposta veiculos user", response.data);
      this.setState({totalVeiculos: parseInt(response.data.TotalVeiculos)});
      this.setState({usuarioNome: response.data.Usuario.Nome.split("")[0]});
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true}); 
    })
    .finally(() => {   
      this.setState({
        loadingGrid: false
      });   
    });
  }

  clickCadastarVeiculo = () => {
    this.props.history.push({
      pathname: `veiculos/cadastrar`,
      state: { qtdVeiculos: this.state.totalVeiculos }
    });
  }
  
  render() {
  return (
    <>
        <LoadingGrid display={this.state.loadingGrid} />
        <div style={{display: (!this.state.loadingGrid ? '' : 'none'), width: '100%'}}>
          <MeuPlanoAtual />
          
          {((this.state.multas.length <= 0) && (this.state.totalVeiculos <= 0)) ? (
              <GridContainer style={{textAlign: 'center'}}>
                <GridItem xs={12} sm={12} md={12}>
                  <p style={{marginTop: 30}}>Olá! Você ainda não fez o cadastro do seu veículo no App. Faça agora e descubra se cometeu alguma infração de trânsito. </p>
                  <Button type="button" onClick={this.clickCadastarVeiculo} style={{backgroundColor: '#00acc1', margin: '50px 0'}}>Cadastrar Veículo</Button>
                </GridItem>
              </GridContainer>
            ) :
            (
              ((this.state.totalVeiculos > 0) && (this.state.multas.length <= 0)) ?
                <p>Parabéns {this.state.usuarioNome}, você não possui nenhuma multa no momento!!!</p>
              :
               <GridMulta rota={'multas/view/'} display={!this.state.loadingGrid} multas={this.state.multas} />
            )}
        </div>
          <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} />
     </>
    );
  }
}