import React from "react";
// @material-ui/core components
import ViewMultaNaoAcompanhada from "components/View/ViewMultaNaoAcompanhada.js";
import LoadingFull from "components/Loading/LoadingFull";
import api from "services/api.js";
import { serverApi } from "services/constante.js";
import { msg } from "services/msg.js";
import MySnackbarErro from "components/Uteis/MySnackbarErro.js";
import MeuPlanoAtual from "components/Uteis/MeuPlanoAtual";
import checaLiberacaoMenu from "services/checaLiberacaoMenu";

export default class MultasNaoAcompanhadasView extends React.Component {
  constructor(props) {
    super(props);

    //checaLiberacaoMenu(props);

    this.state = {
      solicitacoes: [],
      loadingGrid: true,
      isErro: false
    };
  }
  componentDidMount() {
    this.setState({ isErro: false });

    let email_user = localStorage.getItem("CLLogin");

    var formdata = new FormData();
    formdata.append('email', email_user);
    formdata.append('function', "get_solicitacoes_multa_nao_acompanhada");

    api
      .post(serverApi.urlPHP + "/apicdl/usuario.php", formdata)
      .then(response => {
        this.setState({ solicitacoes: response.data.solicitacoes });
      })
      .catch(error => {
        console.error("erro", error.message);
        this.setState({ isErro: true });
      })
      .finally(() => {
        this.setState({
          loadingGrid: false
        });
      });
  }

  render() {
    return (
      <>
        <LoadingFull display={this.state.loadingGrid} />
        <MeuPlanoAtual />

        <ViewMultaNaoAcompanhada props={this.props} solicitacoes={this.state.solicitacoes} />
        <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} />
      </>
    );
  }
}
