import React from "react";
//import { Link } from "react-router-dom";
//import axios from "axios";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import LoadingFull from "components/Loading/LoadingFull";
import Icon from "@material-ui/core/Icon";
import CardAvatar from "components/Card/CardAvatar.js";
import Tooltip from '@material-ui/core/Tooltip';
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import estados from '../../services/estados.json';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import ModalInfo from "views/Checkout/Modais/ModalInfo";
import ReviewCadastroMulta from "./ReviewCadastroMulta";
//import './styles.css'
 
export default class MultaNaoAcompanhadaCadastrar extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state = {
      ProprietarioCondutor: '',      
      NomeCompletoDefesa: '',
      CpfDefesa: '',
      //UF: {"Uf": "RJ"},
      EnderecoCompletos: '',
      Placa: '',
      DataInfracao: '',
      TelefoneContato: '',
      HoraInfracao: '',
      AutoInfracao: '',
      AutoRenainf: '',
      Enquadramento: '',
      Descricao: '',
      Local: '',
      OrgaoEmissor: '',
      Fase: '',
      SolicitacaoMultaNaoAcompanhada_Id: props.location.state.idPedido,
      Email: localStorage.getItem("CLLogin"),
      function: 'cadastrar_multa',
      props: props,
      templates: [],
      TemplateId: '',
      Sexo: '',
      ReviewCad: false,
    };  

    //this.setState({blocking: true});
    
    var formdata = new FormData();
    formdata.append('function', "templates_nao_acompanhada");
    
    api.post(serverApi.urlPHP + '/apicdl/peticao_template.php', formdata).then(response => {
      
      if(response.data.response == "success") {
        this.setState({templates: response.data.templates});
      }

    })
    .catch(error => {
      console.error("ERROR", error.response);
      this.setState({blocking: false});
    })
    .finally( ()=> {
      this.setState({blocking: false});
    });

    
  }

  componentDidMount() {  }
  
  cadastrarVeiculo = () => {
    
    if(!this.validateForm())
    {      
        alert('Todos os campos devem ser preenchidos!');
        return;
    }
    this.setState({ReviewCad: true});

  };

  validateForm() {
    return  this.state.Sexo.length > 0 && this.state.NomeCompletoDefesa.length > 0
            && this.state.CpfDefesa.length > 0 && this.state.TelefoneContato.length > 0
            && this.state.EnderecoCompletos.length > 0 && this.state.Placa.length > 0
            && this.state.DataInfracao.length > 0 && this.state.HoraInfracao.length > 0
            && this.state.AutoInfracao.length > 0 
            && this.state.Enquadramento.length > 0
            && this.state.Local.length > 0 && this.state.OrgaoEmissor.length > 0
            && this.state.Fase.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeEnquadramento = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    this.setState({
      ['Enquadramento']: event.target.options[event.target.selectedIndex].text
    });
  }

  handleChangeProprietarioCondutor = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  limpa_formatacao_cpf_cnpj(cpf_cnpj) {
    if(cpf_cnpj.length <= 14) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    } else if(cpf_cnpj.length <= 18) {
      cpf_cnpj = cpf_cnpj.replace(".", "").replace(".", "");
      cpf_cnpj = cpf_cnpj.replace("/", "");
      cpf_cnpj = cpf_cnpj.replace("-", "");
    }
    return cpf_cnpj;
  }

  //ESPECÍFICA PARA LIMPAR FORMATAÇÃO DE CPF
  handleChangeCPF = event => {
    let valor = event.target.value;
    
    let valor_sem_mask = this.limpa_formatacao_cpf_cnpj(valor);
    if(valor_sem_mask.length == 11) {
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4");
    } else if(valor_sem_mask.length == 14) {
      valor = this.limpa_formatacao_cpf_cnpj(valor);
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5");
    }

    this.setState({
      [event.target.id]: valor
    });
   /*  this.setState({
      [event.target.id]: event.target.value.replace(".","").replace(".","").replace("-","")
    }); */
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  
  render() {
  return (
      <GridContainer>
      <LoadingFull display={this.state.blocking} />
            {this.state.ReviewCad ? 
              <ReviewCadastroMulta data={this.state} /> 
            : 
              <>
                <CardAvatar profile style={{marginTop: "12px", textAlign: 'center', width: '60px'}}>
                  <Icon style={{fontSize: "50px"}}>directions_car</Icon>
                </CardAvatar>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer >
                      <br />
                  </GridContainer>
                  {/* <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <RadioGroup aria-label="ProprietarioCondutor" id="ProprietarioCondutor" name="ProprietarioCondutor" onChange={this.handleChangeProprietarioCondutor}>
                        <FormControlLabel value="Proprietário do veículo" control={<Radio />} label="Proprietário do veículo" />
                        <FormControlLabel value="Condutor abordado" control={<Radio />} label="Condutor abordado" />
                      </RadioGroup>
                    </GridItem>
                  </GridContainer> */}
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <ModalInfo imagem={""} label="Gênero" />
                      <FormControl style={{marginTop: 26, width: '100%'}}>
                        <Select
                            native
                            id="Sexo"
                            value={this.state.Sexo}
                            onChange={this.handleChange}
                            required
                          >
                            <option value={'E'}>Selecione o gênero ao qual sairá na petição</option>
                            <option value={'M'}>Masculino</option>
                            <option value={'F'}>Feminino</option>
                          </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ModalInfo imagem={""} label="Nome" />
                      <TextField
                          id="NomeCompletoDefesa"
                          label={`Nome completo para quem será feita a defesa (nome que sairá na petição)`}
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.NomeCompletoDefesa}
                          fullWidth
                        />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <ModalInfo imagem={""} label="CPF" />
                      <TextField
                        id="CpfDefesa"
                        label="CPF para quem será feita a defesa (CPF que sairá na petição)"
                        margin="dense"
                        onChange={this.handleChangeCPF}
                        value={this.state.CpfDefesa}
                        fullWidth
                        inputProps={{ maxLength: 18 }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <ModalInfo imagem={""} label="Telefone" />
                      <TextField
                        id="TelefoneContato"
                        label="Telefone de contato"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.TelefoneContato}
                        fullWidth
                        inputProps={{ maxLength: 18 }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <ModalInfo imagem={""} label="Endereço (Ex.: Rua Um Dois Três, 10, apto 307 - Realengo - Rio de Janeiro/RJ)" />
                      <TextField
                        id="EnderecoCompletos"
                        label={`Endereço completo`}
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.EnderecoCompletos}
                        helperText=""
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ModalInfo imagem={""} label="Placa" />
                      <TextField
                        id="Placa"
                        label="Placa"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.Placa}
                        fullWidth
                      />
                    </GridItem>
                    
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/data-infracao.jpg"} label="Data da infração" />
                      <TextField
                        id="DataInfracao"
                        label="Data da infração"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.DataInfracao}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/hora-infracao.jpg"} label="Hora da infração" />
                      <TextField
                        id="HoraInfracao"
                        label="Hora da infração"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.HoraInfracao}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/auto-infracao.jpg"} label="Auto de infração" />
                        <TextField
                          id="AutoInfracao"
                          label="Auto de infração"
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.AutoInfracao}
                          fullWidth
                        />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/renainf.jpg"} label="Auto de renainf" />
                        <TextField
                          id="AutoRenainf"
                          label="Auto Renaif"
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.AutoRenainf}
                          fullWidth
                        />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                          <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/enquadramento.jpg"} label="Enquadramento da infração" />
                          <FormControl style={{marginTop: 26, width: '100%'}}>
                            <Select
                              native
                              id="TemplateId"
                              value={this.state.TemplateId}
                              onChange={this.handleChangeEnquadramento}
                              required
                            >
                                <option value={""} >{"Escolha o artigo correpondente a sua multa"}</option>
                                {this.state.templates.map((currentValue, index, arr) => 
                                  <option key={index} value={currentValue.Id} >{currentValue.NomePeticao}</option>
                                )}
                            </Select>
                          </FormControl>
                      </GridItem>
                  </GridContainer>
                  {/* <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/descricao.jpg"} label="Descrição" />
                        <TextField
                          id="Descricao"
                          label="Descrição"
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.Descricao}
                          fullWidth
                        />
                      </GridItem>
                  </GridContainer> */}
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/local.jpg"} label="Local (Descrever o endereço exatamente como aparece no Auto de Infração)" />
                        <TextField
                          id="Local"
                          label="Local da Infração"
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.Local}
                          fullWidth
                        />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/orgao-emissor.jpg"} label="Órgão emissor" />
                        <TextField
                          id="OrgaoEmissor"
                          label="Órgão Emissor"
                          margin="dense"
                          onChange={this.handleChange}
                          value={this.state.OrgaoEmissor}
                          fullWidth
                        />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                      <GridItem md={3}></GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <ModalInfo imagem={"https://apicondutorlegal.azurewebsites.net/apicdl/images/fase.jpg"} label="Fase" />
                          <FormControl style={{marginTop: 26, width: '100%'}}>
                            <Select
                              native
                              id="Fase"
                              value={this.state.Fase}
                              onChange={this.handleChange}
                              required
                            >
                                <option key={0} value={"P"} >{"Defesa Prévia"}</option>
                                <option key={1} value={"I"} >{"1ª Intstância"}</option>
                                <option key={2} value={"II"} >{"2ª Intstância"}</option>
                            </Select>
                          </FormControl>
                      </GridItem>
                  </GridContainer>
                  
                  {/* <GridContainer>
                    <GridItem md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                    <br />
                      <FormControl style={{marginTop: 26, width: '100%'}}>
                        <Select
                          native
                          id="UF"
                          value={this.state.UF.Uf}
                          onChange={this.handleChangeEstado}
                          required
                          inputProps={{
                            disabled: true
                          }}
                        >
                          {estados.map((currentValue, index, arr) => 
                            <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer> */}
                <GridContainer >
                    <br />
                </GridContainer>
                <GridContainer >
                    <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                      <Button color="info" size="md" onClick={this.cadastrarVeiculo}>Revisar pedido</Button>
                    </GridItem>
                </GridContainer>
            </GridItem>
              </>
            }
            
            
      </GridContainer>
    );
  }
}