import React from 'react';

export default function ViewPoliticaDePagamentos() {
   return(
       <>
        A Política de Pagamentos abaixo constitui parte integrante dos Termos e Condições de Uso.<br/><br/>
1. Identificação<br/>
Este site é de propriedade, mantido, e operado por CONDUTOR LEGAL PRESTADORA DE SERVICOS LTDA, CNPJ 35.447.156/0001-08, endereço Av. das Américas, 3434 Bloco 4, 3º andar sala 305, CEP: 22.640-102, Rio de Janeiro, RJ. com Inscrição Estadual isenta. <br/><br/>
2. Contato – CENTRAL DE ATENDIMENTO<br/>
Caso o Usuário necessite de qualquer informação, esclarecimento ou atendimento com relação a esta Política de Pagamentos, a Condutor Legal disponibiliza a CENTRAL DE ATENDIMENTO para receber todas as comunicações que o Usuário desejar fazer.<br/>
A CENTRAL DE ATENDIMENTO opera por meios dos canais de comunicação listados abaixo:<br/>
(a) Eletronicamente, através do e-mail atendimento@condutorlegal.com, durante os horários de funcionamento; e Além disso, a Condutor Legal disponibiliza em seu site a Política de Privacidade e Segurança, Política de Pagamentos, e, finalmente, os Termos e Condições de Uso deste Site. Todos estes documentos podem ser consultados online ou baixados pelos usuários em formato HTML para consulta e arquivo.<br/><br/>
3. Indicação das condições de pagamento na oferta de produtos<br/>
A Condutor Legal disponibilizará no Site, para cada produto, uma página descritiva (Detail Page), da qual constarão informações relativas às suas características, composição, eventuais riscos/chances de o evento em questão não ocorrer, disponibilidade em estoque, condições de pagamento e preço total à vista.<br/><br/>
4. Pedido de Compra – Escolha do meio e condições de pagamento<br/>
Para a aquisição dos produtos, o Cliente deverá acessar sua Conta e seguir as indicações do Site.<br/>
Feita a escolha dos produtos, datas e de suas respectivas quantidades, o Cliente será direcionado à página de pagamento, para nominação de participantes e escolha da forma de pagamento.<br/>
Este é o último momento para que o Cliente identifique e corrija quaisquer erros cometidos nas etapas anteriores.<br/>
Após a finalização da Compra, essa não poderá ser alterada (modificação de quantidades, alteração de produtos, troca da forma de pagamento, participantes, etc.).<br/>
A Condutor Legal imediatamente enviará um e-mail ao Cliente indicando os produtos, o preço, o nome dos participantes e a forma de pagamento.<br/>
A Condutor Legal, visando garantir a segurança dos Clientes, poderá entrar em contato com o Cliente para confirmação da realização de uma Compra, por si ou por terceiros, inclusive para confirmação de dados cadastrais e de pagamento, podendo, ainda, cancelar a Compra, caso haja qualquer inconformidade relacionada a qualquer dado indicado pelo Cliente em todo o processo de realização da compra. O Cliente declara ter lido e estar ciente de todas as disposições dos Termos e Condições de Uso, bem como das Políticas que o compõem.<br/>
Alterações do pedido.<br/>
Como regra geral, a Condutor Legal não aceitará alterações da compra (formas de pagamento) após a finalização de seu processo.<br/>
Caso o Cliente TITULAR da compra queira alterar algum item de sua compra, poderá entrar em contato com a CENTRAL DE ATENDIMENTO para verificação da possibilidade de alteração, que só poderá ser feita se ainda houver tempo hábil para modificação da Compra conforme antecedência e viabilidade técnica/operacional/admnistrativa e financeira do evento.<br/><br/>
5. Meios de Pagamento<br/>
O Cliente poderá pagar suas compras no site pela utilização de apenas um cartão de crédito emitidos no Brasil ou no Exterior em seu nome, seja como titular ou como dependente, pelas seguintes bandeiras: Visa®, Mastercard® e American Express®, Elo®, Maestro®, Diners® Discover® e Boleto Bancário.<br/>
Para segurança de suas operações e visando evitar fraudes, a Condutor Legal se reserva o direito de recusar cartões de crédito de terceiros ou cartões de crédito com dados incompatíveis com a titularidade e/ou leis de ecommerce brasileiras.<br/>
Segurança do pagamento.<br/>
A Condutor Legaldispõe de uma política específica que contempla questões relacionadas à aquisição do produto, inclusive sobre a segurança do pagamento de compras realizadas no Site: Política de Privacidade e Segurança.<br/><br/>
6. Influências dos Meios de Pagamento na Entrega e no Cancelamento de Produtos<br/>
Entrega<br/>
Durante o processo de compra, a Condutor Legal informará ao Cliente uma estimativa de prazo para a confirmação do plano. Essa estimativa leva em conta o tempo necessário para que o gateway de pagamento e o adquirente possam processar o pedido. Normalmente, a aprovação ocorre em poucos minutos e, caso essa confirmação não ocorra o Usuário deve entrar em contato com a Central de Atendimento, por um dos canais supracitados.<br/>
Devolução<br/>
Caso se arrependa de uma compra realizada no Site, o Cliente poderá informar à Condutor Legal por meio da CENTRAL DE ATENDIMENTO e requerer a devolução/estorno do valor e o cancelamento da compra em até 7 (sete) dias corridos a partir da data da compra, conforme previsto no Artigo 49 do Código de Defesa do Consumidor, desde que não seja solicitado nas 48 horas que antecedem o evento ou após a sua realização.<br/>
O procedimento está integralmente descrito na Política de Privacidade e Segurança.<br/>
Caso seja possível efetuar o cancelamento da compra, a Condutor Legal notificará o Cliente informando que aceitou o pedido e providenciará a devolução integral dos valores pagos em conexão com o produto adquirido, incluindo o preço e taxas.<br/>
Se o arrependimento ocorrer com relação a apenas um dos produtos constantes de um pedido, a devolução dos custos será proporcional.<br/>
A devolução de pagamentos feitos por cartão de crédito será realizada por meio de estorno do lançamento, a ser solicitado pela Condutor Legal à administradora do cartão utilizado em, no máximo, 72 (setenta e duas) horas após a aceitação e validação das condições da compra, desde que comprovada a não utilização do produto/evento, o recebimento dos valores por parte da adquirente ou o descumprimento do prazo de cancelamento supracitado. O prazo de efetivação do estorno é de exclusiva responsabilidade da administradora do cartão.
       </>
   );
}