import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import LoadingFull from "components/Loading/LoadingFull";
import Icon from "@material-ui/core/Icon";
import CardAvatar from "components/Card/CardAvatar.js";
import Tooltip from '@material-ui/core/Tooltip';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import estados from '../../services/estados.json';
 
export default class CondutorCorrigirCadastro extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state  = {
        Condutor: {},
        NumeroCnh: '',      
        Cpf: '',      
        UF: {"Uf": "RJ"},
        blocking: false,
        remuneracao: "0",
        EmailNotificacao: ''
    };  
  } 
  componentDidMount(){

    let id = this.props.match.params.id;
      this.setState({isErro: false});

      api.get(serverApi.url + serverApi.versao + '/Habilitacao/' + id + '/Resumo').then(response => {
        this.setState({Condutor: response.data});
        this.setState({NumeroCnh: response.data.NumeroCnh});
        this.setState({Cpf: response.data.Cpf});
        this.setState({EmailNotificacao: response.data.EmailNotificacao});
        if(response.data.UF != null) {
          this.setState({UF: {"Uf": response.data.UF.Uf}});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({isErro: true}); 
       
      })
      .finally(() => {   
        this.setState({
          loadingGrid: false
        });   
      });
  }

  corrigirCadastro = () => {
    
    if(!this.validateForm() )
    {      
        alert('Todos os campos devem ser preenchidos!');
      return;
    }
    
    const {
      Condutor,
      NumeroCnh,
      Cpf,
      EmailNotificacao,
      UF,
      remuneracao
    } = this.state;

    this.setState({blocking: true});

    var querystring = require('querystring');
    const data = querystring.stringify({
      Id: Condutor.Id,
      NumeroCnh,
      Cpf,
      EmailNotificacao,
      UF,
      ExerceAtividadeRemunerada: remuneracao === "1" ? true : false
    });

    api.post(serverApi.url + serverApi.versao + '/Habilitacao/CorrigeCadastro', data).then(response => {
      // handle success
        //console.log(response.data.access_token);
        alert('Sucesso!!!!\nO cadastro do condutor foi atualizado com sucesso!');

        this.props.history.push('/app/condutores');
    })
    .catch(error => {
      // handle error
      console.error(error.response);
      try{
        switch(error.response.data)
        {
          case 'LIMITE_MAX_ATINGIDO':
              alert('O limite de condutores foi atingido!\nEntre em contato conosco e solicite uma mudança no seu plano!');
          break;
          
          default:
              alert(error.response.data);
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar a atualização do cadastro do condutor!\nTente novamente!');

      }
      this.setState({blocking: false});
    })
    .finally( ()=> {
      //this.setState({blocking: false});
    });
   };

  validateForm() {
    return this.state.NumeroCnh.length > 0 && this.state.Cpf.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeEstado = event => {
    this.setState({
      [event.target.id]: {"Uf": event.target.value}
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  
  render() {
  return (
      <GridContainer>
      <LoadingFull display={this.state.blocking} />
            
            <GridItem lg={2}></GridItem>
        <GridItem xs={12} sm={12} md={12} lg={8}>
          <CardAvatar profile style={{marginTop: "10px", width: '70px', textAlign: 'center', paddingTop: '5px'}}>
            <Icon style={{fontSize: "50px"}}>recent_actors</Icon>
          </CardAvatar>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12} >
                    <TextField
                        id="NumeroCnh"
                        label="Número Cnh"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.NumeroCnh}
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12} >
                <br />
                    <TextField
                        id="Cpf"
                        label="Cpf"
                        margin="dense"
                        onChange={this.handleChange}
                        value={this.state.Cpf}
                        fullWidth
                        inputProps={{ maxLength: 11 }}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={12} >
                  <GridContainer>
                    <GridItem xs={10} sm={11} md={11}>
                      <TextField
                          id="EmailNotificacao"
                          label="E-mail Notificaçao"
                          margin="dense"
                          value={this.state.EmailNotificacao}
                          onChange={this.handleChange}
                          helperText=""
                          fullWidth
                        />
                    </GridItem>
                    <GridItem md={1}>
                      <Tooltip title="Se preferir, indique um email para ser copiado nas notificações desta habilitação">
                          <Icon className="icon_info_email">info</Icon>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12} >
                <br />
                <FormControl style={{marginTop: 26, width: '100%'}}>
                    <Select
                      native
                      id="UF"
                      value={this.state.UF.Uf}
                      onChange={this.handleChangeEstado}
                      required
                      inputProps={{
                        disabled: true
                      }}
                    >
                      {estados.map((currentValue, index, arr) => 
                        <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
            </GridContainer>
          <GridContainer>
            <br />
          </GridContainer>
          <GridContainer>
            <GridItem md={3}></GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl component="fieldset">
                <FormLabel>Exerce Atividade Remunerada?</FormLabel>
                <RadioGroup
                  row
                  aria-label="remuneracao"
                  defaultValue="0"
                  name="remuneracao"
                  onChange={e => this.setState({ remuneracao: e.target.value })}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                </RadioGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                <Button color="info" onClick={this.corrigirCadastro}>Corrigir</Button>
                </GridItem>
            </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}