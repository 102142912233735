import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {serverApi} from 'services/constante.js';

// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import bgImage1 from "assets/img/background-login-1.jpeg";
import bgImage2 from "assets/img/background-login-1.jpeg";
import bgImage3 from "assets/img/background-login-1.jpeg";
import bgImage4 from "assets/img/background-login-1.jpeg";
import TextField from '@material-ui/core/TextField';
import bgImageLogo from "assets/img/logo-react.png";
import LoadingFull from "components/Loading/LoadingFull";
import GA from 'services/GoogleAnalytics'


//import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';


const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4];
const imagem = bgImages[Math.floor(Math.random() * bgImages.length)];

export default class EsqueceuSenha extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
        Email: "",
        blocking: false
    };
    localStorage.clear();
    GA.pageview(window.location.pathname);
  } 


  
  recuperarSenha = () => {
    
    if(!this.validateForm() )
    {      
      return;
    }    
    this.setState({blocking: true});

    var querystring = require('querystring');
    const data = querystring.stringify({ Email: this.state.Email})

    axios.post(serverApi.url + serverApi.versao + '/Usuario/EsqueceuSenha', data).then(response => {
      // handle success
        console.log(response.data);
        alert('Sucesso!!!!\nEnviamos um e-mail com o procedimento de recuperação!');
        this.props.history.push('/Login');
    })
    .catch(error => {
      // handle error
      console.error(error.response.data);
      try{
        alert(error.response.data);
      }
      catch(ex)
      {
        alert('Náo foi possível recuperar a senha!\nTente novamente!');

      }
      this.setState({blocking: false});
    })
    .finally( ()=> {
      //this.setState({blocking: false});
    });
   };

  validateForm() {

    if(this.state.Email === null)
    {
      alert('Por favor, informe a senha!');
      return false;
    }

    if(this.state.Email.length <= 0)
    {
      alert('Por favor, informe a senha!');
      return false;
    }

    return true;
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  render() {
    return (
      <>
        <div  style={{paddingTop: "15%", backgroundImage: "url(" + imagem + ")", position: "fixed",
        zIndex: "0",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center", "&:after": {
          position: "absolute",
          zIndex: "3",
          width: "100%",
          height: "100%",
          content: '""',
          display: "block",
          backgroundColor: '#cccccc',
          opacity: ".8"
        } }}></div>
          <LoadingFull display={this.state.blocking} />
          <br />
          <GridContainer>
          <GridItem xs={1} sm={2} md={3} lg={4}>
              </GridItem>
            <GridItem xs={10} sm={8} md={6} lg={4}>
            <Card>
                <CardAvatar profile>
                  <img  src={bgImageLogo} alt="..." style={{width: '80px', margin: "10px"}} />
                </CardAvatar>
                <CardBody>
                <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                          <div  style={{marginTop:'20px', 'width': '100%', textAlign: 'center', fontSize: '19px', fontWeight: 'bold'}}>Recuperação de Senha</div>
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>                            
                      <TextField
                        id="Email"
                        label="E-mail"
                        margin="dense"
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>                                     
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center"}}>
                      <Button onClick={this.recuperarSenha} color="info">Recuperar</Button>                       
                      </GridItem>
                  </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}  style={{textAlign:"center"}}>
                    <Link to={"/Login"} >
                        Cancelar
                      </Link>
                    </GridItem>
                </GridContainer>   
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      </>
    );
  }      
}