import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
    box: {
      width: '30%',
      position: 'absolute',
      top: '35%',
      left: '35%',
      backgroundColor: '#FFF',
      padding: '15px',
      borderRadius: '5px', 
    },
    boxMobile: {
      width: '55%',
      position: 'absolute',
      top: '35%',
      left: '22%',
      backgroundColor: '#FFF',
      padding: '15px',
      borderRadius: '5px',
    },
  }));

  
function LoadingPayment(props) {
  
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');

    return (
      <div style={{position: 'fixed', marginTop:'0px', left: '0px', 'width': '100%', background: 'rgba(120, 120, 120, 0.6)', height: '100%', top: '0px', zIndex: '999998', textAlign: 'center', display: (props.display ? '' : 'none')}}>
        <div className={!matches ? classes.box : classes.boxMobile}>
          <CircularProgress className={classes.progress} />
          <p>Espere um pouco... Estamos processando seu pagamento :)</p>
        </div>
      </div>
    );
}
export default LoadingPayment;