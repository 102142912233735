import React, { Component } from "react";
import 'react-block-ui/style.css';
export default class Sair extends Component {
 
  constructor(props) {
    super(props);
    
    localStorage.clear();
    this.props.history.push('/login');
  } 
  

  render() {
        return (
            <div></div>
        );
      }      
}