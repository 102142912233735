import React from "react";
// @material-ui/core components
import ViewNotificacao from "components/View/ViewNotificacao.js";
import LoadingFull from "components/Loading/LoadingFull";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';

  export default class NotificacaoView extends React.Component {
  
    constructor(props) {
      super(props);
  
      checaLiberacaoMenu(props);

      this.state  = {
        notificacao: {},
        loadingGrid: true
      };
      
      
    } 
    componentDidMount(){
  
      let guid = this.props.match.params.id;

      api.get(serverApi.url + serverApi.versao + '/Notificacao/' + guid + '').then(response => {
        this.setState({
            notificacao: response.data
        });
        
        console.log("retorno", response.data);
        if(response.data.DataLeitura === null)
        {
          api.post(serverApi.url + serverApi.versao + '/Notificacao/MarcarLida', {Guid: guid}).then(response => {
            
          })
        }       
      })
      .catch(error => {
        console.error(error);
       
      })
      .finally(() => {   
        this.setState({
          loadingGrid: false
        });   
      });
      
    }
    
    render() {
    return (
      <>
          <LoadingFull display={this.state.loadingGrid} />
          
            <ViewNotificacao notificacao={this.state.notificacao} />
      </>
    );
  }
}
