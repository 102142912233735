import React, { useState } from 'react';
// core components
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import FileButton from "components/CustomButtons/FileButton.js";
import LoadingFull from "components/Loading/LoadingFull";
import logo from "assets/img/condutor-logo.png";

function ViewRecorrer(props) {
  const [loading, setLoading] = useState(false);

  function recorrer() {
    setLoading(true);

    const arqs = document.querySelectorAll('[name="Arquivos[]"]');
    var formData = new FormData();
    let allDocsValid = true;
    formData.append("GuidMulta", props.Multa.Guid);
    for (let i = 0; i < arqs.length; i += 1) {
      formData.append(arqs[i].getAttribute("dataName"), arqs[i].files[0]);
      if (!arqs[i].files[0]) {
        allDocsValid = false;
        break;
      }
    }

    if (allDocsValid) {
      let url = (props.EVeiculo) ? 'SolicitarRecursoUpload' : 'SolicitarRecursoHabilitacao';

      api.post(serverApi.url + serverApi.versao + '/Multa/' + url, formData).then(response => {
          // handle success
          alert('Obrigado, solicitação enviada com sucesso.');

          setLoading(false);
          props.setOpen(false);
        })
        .catch(error => {
          // handle error
          console.error(error.response);
          try {
            switch (error.response.data) {
              case 'LIMITE_MAX_ATINGIDO':
                alert("Limite máximo atingido do seu plano. Faça um upgrade ou contrate avulso (Meus Extras).");        
                break;
              default:
                alert(error.response.data);
                break;
            }
          } catch(ex) {
            alert('Não foi possível realizar o recurso!\nTente novamente!');
          }
          setLoading(false);
        })
        .finally(() => {});
    } else {
      alert("Anexe todos os documentos!");
      setLoading(false);
    }
  }

  return (
    <>
      <LoadingFull display={loading} />
      <GridContainer>
        <GridItem xs={0} sm={0} md={0} lg={2}></GridItem>
        <GridItem xs={12} sm={12} md={12} lg={8}>
          <div style={{width: '96%', paddingLeft: '2%', paddingTop: '30px'}}>
            <div
              style={{textAlign: 'center', width: '100%', height: 'auto', fontSize: 'calc(22px + (2) * ((100vw - 300px) / (1600 - 300)))', fontWeight: '600', color: '#929191', linHeight: '8.5'}}><p>Antes de prosseguir é importante saber:</p>
            </div>
            <div style={{textAlign: 'center', width: '100%', paddingTop: '20px'}}><img src={logo} style={{width: '20%', maxWidth: '80px'}} /></div>
            <div style={{width: '100%', fontSize: 'calc(14px + (2) * ((100vw - 300px) / (1600 - 300)))', fontWeight: '300', color: '#929191', paddingTop: '30px', paddingLeft: '20px'}}>
              <div style={{width: '100%', paddingTop: '25px'}}><span><img src={require('assets/img/seta-direita.png')} /> </span>Em breve disponibilizaremos o recurso on-line</div>
              <div style={{width: '100%', paddingTop: '25px'}}><span><img src={require('assets/img/seta-direita.png')} /> </span>Entraremos em contato com você para mais detalhes</div>
              <div style={{width: '100%', paddingTop: '25px'}}><span><img  src={require('assets/img/seta-direita.png')} /> </span>Você receberá um e-mail de confirmação</div>
            </div>
          </div>
          <br />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <FileButton
            label="Documento de Identidade ou CNH"
            helper="Anexar documento aberto - frente e verso"
            dataName="DocumentoIdentidade"
          />
          <FileButton
            label="Comprovante de Residência"
            helper="Últimos 3 meses - Somente: Água, Luz, Telefone, Gás, Declaração Associação de Moradores ou Declaração de Residência do Detran"
            dataName="ComprovanteResidencia"
          />
          <FileButton
            label="CRLV"
            helper="Anexar documento aberto - contendo todas as informações"
            dataName="Crlv"
          />
          <FileButton
            label="Documento de Identidade ou CNH do Proprietário do Veículo"
            helper="Anexar documento aberto - frente e verso"
            dataName="DocumentoIdProprietario"
          />
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
              <Button onClick={recorrer} color="info">Recorrer</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}
export default ViewRecorrer;
