/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Home from "@material-ui/icons/Home";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import ExitToApp from "@material-ui/icons/ExitToApp";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DescriptionIcon from '@material-ui/icons/Description';

// core components/views for Admin layout
import Sair from "views/Login/Sair";
import Login from "views/Login/Login.js";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Condutores from "views/Condutor/Condutores.js";
import CondutorView from "views/Condutor/CondutorView";
import CondutorCadastrar from "views/Condutor/CondutorCadastrar.js";
import CondutorCorrigirCadastro from "views/Condutor/CondutorCorrigirCadastro.js";

import Multas from "views/Multa/Multas.js";
import MultaView from "views/Multa/MultaView";
import RecursoView from "views/Recurso/RecursoView.js";
import MultasNaoAcompanhadasView from "views/MultaNaoAcomoanhada/MultasNaoAcompanhadasView";
import MultaNaoAcompanhadaCadastrar from "views/MultaNaoAcomoanhada/MultaNaoAcompanhadaCadastrar";
import HabilitacaoMultaView from "views/Multa/HabilitacaoMultaView";
import VeiculoView from "views/Veiculo/VeiculoView";
import Veiculos from "views/Veiculo/Veiculos.js";
import PerfilUsuario from "views/PerfilUsuario/PerfilUsuario.js";
import VeiculoCadastrar from "views/Veiculo/VeiculoCadastrar";
import VeiculoCorrigirCadastro from "views/Veiculo/VeiculoCorrigirCadastro";
import Notificacoes from "views/Notificacoes/Notificacoes";
import NotificacaoView from "views/Notificacoes/NotificacaoView";
import Plano from "views/Plano/Plano";
import PlanoDetalhe from "views/Plano/PlanoDetalhe";
import Planos from "views/Plano/Planos";
import ProdutosExtras from "views/ProdutosExtras/ProdutosExtras";
import FormaPagamento from "views/Checkout/FormaPagamento";
import Checkout from "views/Checkout/Checkout";
import PicPay from "views/Checkout/PicPay";
import Pix from "views/Checkout/Pix";
import Boleto from "views/Checkout/Boleto";
import Termos from 'views/Termos/Termos';


const dashboardRoutes = [{
    path: "/login",
    name: "Sair",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: Login,
    layout: "",
    invisible: true,
    enableBack: false
  },
  {
    path: "/inicial",
    name: "Comece aqui",
    rtlName: "لوحة القيادة",
    icon: Home,
    component: DashboardPage,
    layout: "/app",
    invisible: false,
    enableBack: false,
  },
  {
    path: "/condutores",
    name: "Minhas CNHs",
    rtlName: "قائمة الجدول",
    icon: "recent_actors",
    component: Condutores,
    layout: "/app",
    invisible: false,
    enableBack: false
  },
  {
    path: "/veiculos",
    name: "Meus Veículos",
    rtlName: "ملف تعريفي للمستخدم",
    icon: DirectionsCar,
    component: Veiculos,
    layout: "/app",
    invisible: false,
    enableBack: false
  },
  {
    path: "/multas",
    name: "Minhas Multas",
    rtlName: "قائمة الجدول",
    icon: "contactless",
    component: Multas,
    layout: "/app",
    invisible: false,
    enableBack: false
  },
  {
    path: "/recursos",
    name: "Meus Recursos",
    rtlName: "قائمة الجدول",
    icon: FeaturedPlayListOutlinedIcon,
    component: RecursoView,
    layout: "/app",
    invisible: false,
    enableBack: false
  },
  {
    path: "/multas-nao-acompanhadas",
    name: "Multas Não Acompanhadas",
    rtlName: "قائمة الجدول",
    icon: FeaturedPlayListOutlinedIcon,
    component: MultasNaoAcompanhadasView,
    layout: "/app",
    invisible: false,
    enableBack: false
  },
  {
    path: "/multas-nao-acompanhadas/cadastrar-multa",
    name: "Multas Não Acompanhadas - Cadastrar Multa",
    rtlName: "قائمة الجدول",
    icon: FeaturedPlayListOutlinedIcon,
    component: MultaNaoAcompanhadaCadastrar,
    layout: "/app",
    invisible: true,
    enableBack: false
  },
  {
    path: "/planos",
    name: "Meus Planos",
    rtlName: "قائمة الجدول",
    icon: FeaturedPlayListOutlinedIcon,
    component: Plano,
    layout: "/app",
    invisible: false,
    enableBack: true
  },
  {
    path: "/produtos-extras",
    name: "Meus Extras",
    rtlName: "قائمة الجدول",
    icon: AddShoppingCartIcon,
    component: ProdutosExtras,
    layout: "/app",
    invisible: false,
    enableBack: true
  },
  {
    path: "/notificacoes",
    name: "Minhas Notificações",
    rtlName: "قائمة الجدول",
    icon: NotificationImportant,
    component: Notificacoes,
    layout: "/app",
    invisible: false,
    enableBack: true
  },
  {
    path: "/perfil",
    name: "Meus Dados",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: PerfilUsuario,
    layout: "/app",
    invisible: false,
    enableBack: true
  },
  //Rotas ocultas
  {
    path: "/veiculos/view/:guid",
    name: "Dados do Veículo",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: VeiculoView,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/detalhes/:idvenda",
    name: "Plano Detalhes",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: PlanoDetalhe,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/contratar",
    name: "Planos Contratação",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Planos,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/contratar/forma-pagamento/:idplano?/:idplanodetalhe?",
    name: "Planos Contratação",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: FormaPagamento,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/contratar/:idplano?/:idplanodetalhe?",
    name: "Planos Contratação",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Checkout,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/pe/contratar/",
    name: "Pagamento",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Checkout,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/contratar/picpay/:idplano/:idplanodetalhe",
    name: "Planos Contratação",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: PicPay,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/pe/contratar/picpay/",
    name: "Pagamento",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: PicPay,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/planos/contratar/boleto/:idplano/:idplanodetalhe",
    name: "Planos Contratação",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Boleto,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/pe/contratar/boleto/",
    name: "Pagamento",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Boleto,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/pe/contratar/pix/",
    name: "Pagamento",
    rtlName: "قائمة الجدول",
    icon: 'account_box',
    component: Pix,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/veiculos/cadastrar",
    name: "Cadastrar Veículo",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: VeiculoCadastrar,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/veiculos/corrigircadastro/:id",
    name: "Corrigir Cadastro do Veículo",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: VeiculoCorrigirCadastro,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/condutores/view/:guid",
    name: "Dados do Condutor",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: CondutorView,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/condutores/cadastrar",
    name: "Cadastrar CNH",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: CondutorCadastrar,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/condutores/corrigircadastro/:id",
    name: "Corrigir Cadastro do Condutor",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: CondutorCorrigirCadastro,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/multas/view/:id",
    name: "Dados da Multa",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: MultaView,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/multas/habilitacao/view/:guid",
    name: "Dados da Multa da Habilitação",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: HabilitacaoMultaView,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/notificacoes/view/:id",
    name: "Notificação",
    rtlName: "قائمة الجدول",
    icon: NotificationImportant,
    component: NotificacaoView,
    layout: "/app",
    invisible: true,
    enableBack: true
  },
  {
    path: "/termos/",
    name: "Termos",
    rtlName: "قائمة الجدول",
    icon: DescriptionIcon,
    component: Termos,
    layout: "/app",
    invisible: false,
    enableBack: true
  },
  {
    path: "/sair",
    name: "Sair desta conta",
    rtlName: "قائمة الجدول",
    icon: ExitToApp,
    component: Sair,
    layout: "",
    invisible: false,
    enableBack: false
  },
];

export default dashboardRoutes;
