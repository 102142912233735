import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

  
function SemRegistro(props) {
  
    return (
        <div style={{color: 'red', marginLeft: 20, display: (props.Exibe ? '' : 'none')}}>
        <br/>
            {props.Texto}
      </div>
    );
}
export default SemRegistro;