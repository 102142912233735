import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.js";
import { Alert, Button } from 'react-bootstrap';
import LoadingFull from "components/Loading/LoadingFull";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
    fontSize: 11
  },
  title: {
    marginTop: theme.spacing(2),
  },
  logoPix: {
      textAlign: "right",
        '& svg': {
            width: 50,
            height: 50
        }
  },
  helperTextPE: {
    marginTop: -10
  },
  boxHotmart: {
      border: "5px dotted red",
      padding: 15,
      backgroundColor: "#EEE"
  },
  itemHotmart: {
      padding: "0px 5px",
      backgroundColor: "yellow",
      marginTop: 10
  },
  labelPE: {
    '& .MuiTypography-root': {
        color: '#000'
    }
  },
  iconArrowPE: {
      marginTop: "-9px",
      marginRight: "3px",
      color: "red"
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    width: 745,
    marginLeft: 'auto',
    marginRight: 'auto',
    },
},
paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
    },
},
  
}));

export default function ReviewCadastroMulta(props) {
    const classes = useStyles();

    const [blocking, setBlocking] = useState(false);
    const [mensagemPedido, setMensagemPedido] = useState('');
    const [typeMessage, setTypeMessage] = useState('');

    function cadastrarVeiculo() {
       
        setBlocking(true);
    
        var querystring = require('querystring');
        const data = querystring.stringify(props.data);
        
        api.post(serverApi.urlPHP + '/apicdl/multa_nao_acompanhada.php', data).then(response => {
          
          setBlocking(false);
          
            if (response.data.response == "success") {
                setMensagemPedido(response.data.message);
                setTypeMessage("success");
            }
        })
        .catch(error => {
          setBlocking(false);
        })
        .finally( ()=> {
            setBlocking(false);
        });
    
    };

    return (
        <main className={classes.layout}>
            <LoadingFull display={blocking} />
            <Paper className={classes.paper}>
                {mensagemPedido == '' ?
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados Pessoais
                        </Typography>
                        <List disablePadding>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Gênero" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {props.data.Sexo == 'M' ? 'Masculino' : 'Feminino'}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Nome completo" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {props.data.NomeCompletoDefesa}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="CPF" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {props.data.CpfDefesa}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Endereço" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {props.data.EnderecoCompletos}
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Telefone" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {props.data.TelefoneContato}
                                </Typography>
                            </ListItem>
                        </List>
                        <Grid container spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12}>
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    Dados da multa
                                </Typography>
                                <List disablePadding>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Placa" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.Placa}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Data da infração" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.DataInfracao}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Hora da infração" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.HoraInfracao}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Auto de infração" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.AutoInfracao}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Auto de renainf" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.AutoRenainf}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Enquadramento" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.Enquadramento}
                                        </Typography>
                                    </ListItem>
                                    {/* <ListItem className={classes.listItem}>
                                        <ListItemText primary="Descrição" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.Descricao}
                                        </Typography>
                                    </ListItem> */}
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Local" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.Local}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Órgão emissor" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.OrgaoEmissor}
                                        </Typography>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary="Fase" />
                                        <Typography variant="subtitle1" className={classes.total}>
                                            {props.data.Fase == 'II' ? 'Recurso de 2ª instância' : props.data.Fase == 'I' ? 'Recurso de 1ª instância' : 'Defesa prévia'}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                                <Button color="info" size="md" onClick={cadastrarVeiculo}>Cadastrar</Button>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            Obrigado por cadastrar sua multa
                        </Typography>
                        <Alert variant="filled" severity={typeMessage}>
                            {mensagemPedido}
                        </Alert>
                    </React.Fragment>
                }
            </Paper>
        </main>
    );
}