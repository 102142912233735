import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export default function MySnackbarSuccess(props) {

  return (
    <div>

      <Snackbar
        open={props.showAlert}
        onClick={props.clickClose}
        ContentProps={{
            'aria-describedby': 'snackbar-fab-message-id',
            style:{backgroundColor: '#4caf50', textAlign: 'center'}
        }}
        message={<span id="message-id">{props.mensagem}</span>}
        action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.clickClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
        }
        />
    </div>
  );
}
