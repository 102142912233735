import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {myGrid} from "assets/jss/material-dashboard-react.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  inline: {
    display: 'inline'
  },
  temRecurso: {
      color: '#3be93b'
  },
  naoTemRecurso: {
    color: 'red'
  },
  simpleValue: {
      textAlign: 'left',
      marginLeft: 10
  },
}));

const myGridStyle = makeStyles(myGrid);

 function GridMulta(props) {
  
    // const classesGrid = myGridStyle();
    // const classesTaglabel = myTagLabelStyle();
    const classes = useStyles();
    const classesGrid = myGridStyle();
  return (
    <div style={{display: (props.display ? '' : 'none')}}>
    <List className={classes.root}>
        <div className={classesGrid.center + ' ' + classesGrid.boxSimpleLineTitulo}>
            <GridContainer>
                <GridItem xs={5} sm={2} md={2} lg={2}>
                    <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>Auto Infração</div>
                </GridItem>
                
                <GridItem xs={2} sm={2} md={1} lg={1}>
                    <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>Valor</div>
                </GridItem>

                <Hidden xsDown>
                    <GridItem xs={1} sm={2} md={1} lg={1}>
                        <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>Pontuação</div>
                    </GridItem>
                </Hidden>

                <GridItem xs={1} sm={2} md={1} lg={1}>
                    <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>Descrição</div>
                </GridItem>
                
            </GridContainer>
        </div> 
      {props.multas.map((multa, index) => {
        return ( 
            <Link to={props.rota + multa.Guid} key={index} >
                <div className={classesGrid.center + ' ' + classesGrid.boxSimpleLine}>
                    <GridContainer>
                        <GridItem xs={5} sm={2} md={2} lg={2}>
                            <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>
                                {/* { multa.TemRecurso ? 
                                    (
                                        <GridContainer>
                                            <GridItem xs={2} sm={3} md={4} lg={3}>
                                                <CheckCircleIcon className={classes.temRecurso} />
                                            </GridItem>
                                            <GridItem xs={4} sm={6} md={6} lg={6}>
                                                <p>Legal, solicitei</p>
                                            </GridItem>
                                        </GridContainer>
                                    ) : (
                                        <GridContainer>
                                            <GridItem xs={2} sm={4} md={4} lg={3}>
                                                <CheckCircleOutlineIcon className={classes.naoTemRecurso} />
                                            </GridItem>
                                            <GridItem xs={4} sm={6} md={6} lg={6}>
                                                <p style={{color: 'red'}}><strong>Recorrer agora</strong></p>
                                            </GridItem>
                                        </GridContainer>
                                    )
                                } */}
                                {multa.AutoInfracao}
                            </div>
                        </GridItem>
                        
                        <GridItem xs={2} sm={2} md={1} lg={1}>
                            <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>{`R$ ${multa.Valor}`}</div>
                        </GridItem>
                        
                        <Hidden xsDown>
                            <GridItem xs={4} sm={2} md={1} lg={1}>
                                <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>{multa.Pontos}</div>
                            </GridItem>
                        </Hidden>

                        <GridItem xs={3} sm={2} md={1} lg={1}>
                            <div style={{whiteSpace: "nowrap"}} className={classes.simpleValue}>{multa.Descricao}</div>
                        </GridItem>
                        
                        
                    </GridContainer>
                </div>
            </Link>
          );
        })}
      </List>
    </div>
    );
}
export default GridMulta;