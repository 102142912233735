import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemProdutosExtras: {
    fontWeight: 500,
    padding: 5,
    color: "#FFF",
    backgroundColor: "#000"
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  helperTextPE: {
    marginTop: -10
  },
  boxHotmart: {
      border: "5px dotted red",
      padding: 15,
      backgroundColor: "#EEE"
  },
  itemHotmart: {
      padding: "0px 5px",
      backgroundColor: "yellow",
      marginTop: 10
  },
  labelPE: {
    '& .MuiTypography-root': {
        color: '#000'
    }
  },
  iconArrowPE: {
      marginTop: "-9px",
      marginRight: "3px",
      color: "red"
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Review(props) {
  const classes = useStyles();
  const [checkboxPE, setCheckboxPE] = useState(new Map());
  const [totalVenda, setTotalVenda] = useState(0);
  let total_plano = 0;

  useEffect(() => {          
      total_venda();
  },[props] );

  function total_venda() {
    props.items.map(function(plano) {
      let periodicidade = 0;
      if (plano.Periodicidade == "A") {
        periodicidade = 12;
      } else if(plano.Periodicidade == "S") {
        periodicidade = 6;
      } else if (plano.Periodicidade == "M") {
        periodicidade = 1;
      }
      total_plano = (total_plano + (parseFloat(plano.Preco) * periodicidade));
    });
    props.pesSolo.map(function(item) {
      if (item.Quantidade) {
        total_plano = total_plano + item.Preco * item.Quantidade;
      } else total_plano = total_plano + item.Preco;
    });
    setTotalVenda(total_plano)
  }
  //total_venda();

  function formataImprecaoCartao(numero_cartao) {
    return parseInt(numero_cartao).toString().substr(-4);
  }

  function mascaraCpf(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }

  function imprimeVigencia(vigencia) {
    let vigencia_formatada = "";
    if (vigencia == 'A') {
        vigencia_formatada = "Periodicidade Anual";
    } else if(vigencia == 'S') {
        vigencia_formatada = "Periodicidade Semestral";
    } else if(vigencia == 'M') {
        vigencia_formatada = "Periodicidade Mensal";
    }
    return vigencia_formatada;
  }

  function formataValor(valor) {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  function handleChangeCheckbox(e) {
    const valorPE = e.target.value;
    const item = e.target.name;
    const isChecked = e.target.checked;
    setCheckboxPE(checkboxPE.set(item, isChecked));
    
    if(isChecked) {
        setTotalVenda(totalVenda + parseFloat(valorPE));
    } else {
        setTotalVenda(totalVenda - parseFloat(valorPE));
    }
    props.handleChangeGuidsPE(item, 1)
}

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Resumo do pedido
      </Typography>
      <List disablePadding>
        {props.items.map((plano, index, array) => (
          <ListItem className={classes.listItem} key={index}>
          <ListItemText primary={plano.Descricao} secondary={imprimeVigencia(plano.Periodicidade)} />
          <Typography variant="body2">{`${formataValor(plano.Preco)}/mês`}</Typography>
        </ListItem>
        ))}
        {props.pesSolo.map((item, index, array) => (
          <ListItem className={classes.listItem} key={index}>
            <ListItemText primary={item.Nome} secondary={item.Descricao} />
            <Typography variant="body2">{formataValor(item.Preco)}</Typography>
          </ListItem>
        ))}
        <div className={classes.boxHotmart} style={{ display: (props.pes.length > 0) ? 'block' : 'none'  }}>
          <Typography variant="subtitle1" className={classes.listItemProdutosExtras}>
              Compre +
          </Typography>
          <div className={classes.itemHotmart}>
              <FormControl component="fieldset">
                  <FormGroup>
                      {props.pes.map((item, index, array) => (
                          <div key={item.Guid}>
                              <ArrowForwardIcon className={classes.iconArrowPE} />
                              <FormControlLabel
                                  control={<GreenCheckbox checked={checkboxPE.get(item.name)} value={item.ValorProduto} name={item.Guid} onChange={handleChangeCheckbox} />}
                                  label={`${item.NomeProduto} - ${formataValor(item.ValorProduto)}`}
                                  className={classes.labelPE}
                              />
                              <FormHelperText className={classes.helperTextPE}>{item.DescricaoProduto}</FormHelperText>
                          </div>
                      ))}
                  </FormGroup>
              </FormControl>
          </div>
        </div>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {formataValor(totalVenda)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item container direction="column" xs={12} sm={12}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Dados de pagamento
          </Typography>
          <Grid container>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{'Bandeira'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{props.stateCardBrand}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{'Nome como consta no cartão'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{props.stateCardName}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{'Número do cartão'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{`******${formataImprecaoCartao(props.stateCardNumber)}`}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{'Data de expiração'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{`${props.stateCardMes}/${props.stateCardAno}`}</Typography>
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{'CPF'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{mascaraCpf(props.stateCardCpf)}</Typography>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}