import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '16px',
  },
  inline: {
    display: 'inline'
  },
}));


 function GridNotificacao(props) {
    // const classesGrid = myGridStyle();
    // const classesTaglabel = myTagLabelStyle();
    const classes = useStyles();
  return (
    <>
    <List className={classes.root}>      
      {props.notificacoes.map((notificacao, index) => {
        let leitura = (notificacao.DataLeitura != null) ? "300" : "bold";
        return ( 
          <React.Fragment key={index}>
            {/* <Link to={"notificacoes/view/" + notificacao.Guid} > */}
              <ListItem alignItems="flex-start" >
                <ListItemAvatar>
                <Icon>notification_important</Icon>
                </ListItemAvatar>
                <ListItemText
                  primary={<React.Fragment>
                    <Typography
                        component="span"
                        className={classes.titulo}
                        color="textPrimary"
                        style={{fontWeight: leitura}}
                      >
                    {notificacao.Assunto}
                    </Typography>
                    </React.Fragment>}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {moment(notificacao.DataCriacao).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {/* </Link> */}
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>
    </>
    );
}
export default GridNotificacao;