import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReviewPix from "./ReviewPix";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import { array } from "prop-types";

const useStyles = makeStyles((theme) => ({
    boxPayment: {
        width: '60%',
        margin: '0 auto',
        textAlign: 'center',
        padding: 13,
        backgroundColor: '#f9f7f7',
        borderRadius: 5,
    },
    boxPaymentMobile: {
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        padding: 13,
        backgroundColor: '#f9f7f7',
        borderRadius: 5,
    },
    titlePicPay: {
        fontSize: 20,
        fontFamily: 'Montserrat,sans-serif'
    },
    descriptionPicPay: {
        color: '#716d6d',
        paddingTop: 8,
        textAlign: 'center',
        fontFamily: 'Montserrat,sans-serif',
        fontSize: 15
    },
    counterPicPay: {
        fontSize: 10,
        fontFamily: 'Montserrat,sans-serif',
        color: '#716d6d',
        textAlign: 'center',
        marginBottom: 0
    },
    boxQrCode: {
        width: '80%',
        margin: '0 auto',
        border: 'rgba(231,242,251,.45)',
        borderStyle: 'groove'
    },
    qrCode: {
        width: '100%'
    },
    infoCodeOrder: {
        fontSize: 10,
        fontFamily: 'Montserrat,sans-serif',
        color: '#716d6d',
        textAlign: 'center',
    },
    infoCodeOrderSpan: {
        fontWeight: 600
    },
    linkPicPay: {
        color: '#21c25e',
        fontFamily: 'Montserrat,sans-serif',
        textAlign: 'center',
        fontWeight: 800,
    },
    linkPagamento: {
        color: '#716d6d',
        fontFamily: 'Montserrat,sans-serif',
        fontSize: 10,
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    logo: {
        textAlign: 'center',
        '& svg': {
            width: '25%',
            marginBottom: 5
        }
    }
}));

export default function Pix(props) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [payment, setPayment] = useState(false);
    const [contador, setContador] = useState(0);
    const [paymentCode, setPaymentCode] = useState("");
    const [paymentLink, setPaymentLink] = useState("");
    const [paymentQrCode, setPaymentQrCode] = useState("");
    const [codeOrder, setCodeOrder] = useState("");
    const [mensagemSucesso, setMensagemSucesso] = useState("");
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [displayNone, setDisplayNone] = useState(false);
    const [pe, setPE] = useState([]);// Produtos Extras
    const [guidsPE, setGuidsPE] = useState([]);// Guids Produtos Extras
    const [pesSolo, setPesSolo] = useState([]);// Produtos Extras SEPARADOS

    const matches = useMediaQuery('(max-width:600px)');

    var timer =  null;
    var timerPayment =  null;

    useEffect(() => {          
        carregarDados();
    },[props] );

    useEffect(() => {
        if(codeOrder != "") {
            consultPayment();
        }
    },[codeOrder] );

    async function carregarDados() {
        //setLoadingGrid(true);
        
        let prodExtras = [];
        let id_plano = props.match.params.idplano;
        let id_plano_detalhe = props.match.params.idplanodetalhe;
        
        if (id_plano != undefined && id_plano_detalhe != undefined) {
            var formdata = new FormData();
            formdata.append('function', 'get_plano_by_id_plano_id_plano_detalhe');
            formdata.append('id_plano', id_plano);
            formdata.append('id_plano_detalhe', id_plano_detalhe);

            await api.post(serverApi.urlPHP + '/apicdl/plano.php', formdata)
            .then(function (response) {
                //console.log(response);
                let plano = response.data.plano;
                setItems([{Descricao: plano.Descricao, Periodicidade: plano.Periodicidade, Preco: plano.Preco}]);

                //GOOGLE ANALYTICS
                /* window.gtag('event', 'select_content', {
                    "content_type": "product",
                    "items": [
                    {
                        "id": id_plano + "_" + id_plano_detalhe,
                        "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                        "brand": "Condutor Legal",
                        "category": "Plano",
                        "quantity": 1,
                        "price": parseFloat(plano.Preco)
                    }
                ]}); */
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {   
                //setLoadingGrid(false);
                produtosExtras();
            });
        } else {
            let guids = "";
            let quant = "";
            if(props.location.state != undefined) {
                guids = props.location.state.guidspesolo;
                quant = props.location.state.guidsquantity;
            }

            for (var [key, value] of guids) {
                let guidAtual = key;
                const quantAtual = quant.get(guidAtual);
                //let prodExtras = [];
                await api.get(serverApi.url + `${serverApi.versao}/ProdutoExtra/Resumo/${guidAtual}`)
                .then(function (response) {
                    let produto = response.data;
                    prodExtras.push({Guid: produto.Guid, Nome: produto.NomeProduto, Descricao: produto.DescricaoProduto, Preco: produto.ValorProduto, Quantidade: quantAtual});
                    //ABASTECER ARRAY DE GUIDS
                    handleChangeGuidsPE(produto.Guid, quantAtual);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {   
                    setLoadingGrid(false);
                });
                
            }
            setPesSolo(prodExtras);
        }
        produtosExtras(prodExtras);
    }

    async function produtosExtras(prodExtras) {
        await api.get(serverApi.url + '/api/appUsuario/v1/ProdutoExtra/Todos')
        .then(function (response) {
            let listaExtrasBd = response.data;
            let listaExtrasIguaisTemp = [];
            for (let index = 0; index < listaExtrasBd.length; index++) {
                const element = listaExtrasBd[index];
                for (let i = 0; i < prodExtras.length; i++) {
                    const element2 = prodExtras[i];
                    if(element.Guid == element2.Guid) {
                        listaExtrasIguaisTemp.push(element);
                    } 
                }
            }
            for (let index = 0; index < listaExtrasIguaisTemp.length; index++) {
                const element = listaExtrasIguaisTemp[index];
                listaExtrasBd.splice(listaExtrasBd.indexOf(element), 1);
            }
            setPE(listaExtrasBd);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {   
            //setLoadingGrid(false);
        });
    }

    async function handlePayment () {
        setLoadingGrid(true);
        let email_user = localStorage.getItem("CLLogin");
        let id_plano = props.match.params.idplano;
        let id_plano_detalhe = props.match.params.idplanodetalhe;
        let guids_produtos_extras = guidsPE;
        
        var formdata = new FormData();
        formdata.append('email', email_user);
        //VERIFICA SE TEM PLANO ENVOLVIDO NA COMPRA
        if (id_plano != undefined && id_plano_detalhe != undefined) {
            formdata.append('plano', id_plano);
            formdata.append('plano_detalhe', id_plano_detalhe);
        }
        formdata.append('guids_produtos_extras', JSON.stringify(guids_produtos_extras));
        formdata.append('payment_method', "PIX");
        
        await api.post(serverApi.urlPHP + '/apicdl/ecommerce/gerapedido2.php', formdata)
        .then(function (response) {
            startTimer();
            let responseServer = response.data;
            if(responseServer.success == "success") {
                setPayment(true);
                if(responseServer.pagamento) {
                    setPaymentCode(responseServer.pagamento.qr_codes[0].text);
                    setPaymentLink('');
                    setPaymentQrCode(responseServer.pagamento.qr_codes[0].links[0].href);
                    setCodeOrder(responseServer.IdTransacao);
                }
                
                //GOOGLE ANALYTICS
                /* let itemsGA = [];
                if (id_plano != undefined && id_plano_detalhe != undefined) {
                    items.map(function(plano) {
                        itemsGA.push({
                            "id": responseServer.referenceId + Math.floor(Math.random() * 99999),
                            "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                            "brand": "Condutor Legal",
                            "category": "Plano",
                            "list_position": 1,
                            "quantity": 1,
                            "price": parseFloat(plano.Preco)
                        });
                    });
                }
                window.gtag('event', 'purchase', {
                    "transaction_id": responseServer.referenceId,
                    "value": 2.50,
                    "currency": "BRL",
                    "tax": 0.00,
                    "shipping": 0,
                    "items": itemsGA
                }); */
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {   
            setLoadingGrid(false);
        });
    }

    function startTimer() {
        let tempo = new Number();
        // Tempo em segundos -> tempo de validade do qrCode do Pix
        tempo = 300;

        if (timer != null) {
            clearInterval(timer);
            timer = null;
        } else {
            timer = setInterval(() => {
                let horaImprimivel ="";
        
                // Se o tempo não for zerado
                if((tempo - 1) >= 0) {

                    // Pega a parte inteira dos minutos
                    var min = parseInt(tempo/60);
                    // Calcula os segundos restantes
                    var seg = tempo%60;

                    // Formata o número menor que dez, ex: 08, 07, ...
                    if(min < 10){
                        min = "0"+min;
                        min = min.substr(0, 2);
                    }
                    if(seg <=9){
                        seg = "0"+seg;
                    }

                    // Cria a variável para formatar no estilo hora/cronômetro
                    horaImprimivel = '00:' + min + ':' + seg;
                    setContador(horaImprimivel);

                    // diminui o tempo
                    tempo--;
                } else {
                    setContador("00:00:00");
                    setDisplayNone(true);
                }
                
            }, 1000);
        }
    }

    async function consultPayment() {
        
        if (timerPayment != null) {
            clearInterval(timerPayment);
            timerPayment = null;
        } else {
            timerPayment = setInterval(async () => {
                var formdata = new FormData();
                formdata.append('function', "get_transaction_by_transaction_id");
                formdata.append('transaction_id', codeOrder);
                await api.post(serverApi.urlPHP + '/apicdl/transacao.php', formdata)
                .then(function (response) {
                    let responseServer = response.data;
                    if(responseServer.response == "success") {
                        if(responseServer.transaction.DescricaoStatusGateway == "PAID") {
                            setMensagemSucesso("Recebemos a confirmação de pagamento, em breve você receberá um e-mail com as informações do seu pedido.");
                            clearInterval(timerPayment);
                        }
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                })
                .finally(() => {   
                    
                });
                
            }, 5000);
        }
    }

    function handleChangeGuidsPE(guid, quantidade) {
        let listaGuids = guidsPE;
        const index = listaGuids.findIndex(item => item.guid === guid);
        if (index < 0) {
            listaGuids.push({ guid, quantidade });
        } else {
            listaGuids.splice(index, 1);
        }
        setGuidsPE(listaGuids);
    }

    return (
        <>
            <LoadingGrid display={loadingGrid} />
            <div style={{display: (!loadingGrid ? '' : 'none')}}>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h4" align="center">
                            Checkout
                        </Typography>
                        <Stepper activeStep={1} className={classes.stepper}>
                            <Step>
                                {payment ? ( 
                                    <StepLabel>{"Pagamento"}</StepLabel>
                                ) : (
                                    <StepLabel>{"Resumo do pedido"}</StepLabel>
                                )}
                            </Step>
                        </Stepper>
                        <React.Fragment>
                            {payment && mensagemSucesso == "" ? (
                                <React.Fragment>
                                    <div className={classes.logo}>
                                        <svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z"></path><path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z"></path></g></svg>
                                    </div>
                                    <div className={matches ? classes.boxPaymentMobile : classes.boxPayment}>
                                        <Typography variant="h4" classes={{ root: classes.titlePicPay }} align="center" gutterBottom>
                                            <b>Pague com PIX</b>
                                        </Typography>
                                        <p className={classes.descriptionPicPay}>Abra o app do seu banco em seu telefone acesso a seção do PIX e escaneie o código abaixo:</p>
                                        <p className={classes.counterPicPay}>Este código expira em: {contador}</p>
                                        <div className={classes.boxQrCode}>
                                            <img className={classes.qrCode} style={{display: (displayNone ? 'none' : '')}} src={paymentQrCode} />
                                            <div style={{display: (!displayNone ? 'none' : '')}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 94 94" width="210">
                                                    <g fill="none" fill-rule="evenodd" stroke="#ED1846" stroke-width="3" transform="translate(2 2)">
                                                        <circle cx="45" cy="45" r="45"></circle>
                                                        <path d="M26.823 63.346L63.185 27 26.823 63.346zM26.823 27l36.346 36.362L26.823 27z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        {/* <a href={paymentLink} style={{display: (displayNone ? 'none' : '')}} className={classes.linkPagamento}>Se preferir clique aqui para efetuar o pagameto</a> */}
                                        <p className={classes.infoCodeOrder} style={{display: (displayNone ? 'none' : '')}}>Pix copia e cola: <input type="text" value={paymentCode} /></p>
                                        {/* <a href="https://blog.picpay.com/apresentamos-o-seu-c%C3%B3digo-pessoal-receber-pagamentos-ficou-ainda-mais-f%C3%A1cil-7e0a5910fe3b" target="_blank" className={classes.linkPicPay}>Como faço para escanear?</a> */}
                                    </div>
                                </React.Fragment>
                            ) : (mensagemSucesso == "" ? (
                                <React.Fragment>
                                    <ReviewPix items={items} pes={pe} pesSolo={pesSolo} handleChangeGuidsPE={handleChangeGuidsPE} />
                                    <div className={classes.buttons}>
                                        <Button variant="contained" onClick={handlePayment} color="primary" className={classes.button}>
                                            Pagar
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        Obrigado por seu pedido.
                                    </Typography>
                                    <Alert variant="filled" severity={"success"}>
                                        {mensagemSucesso}
                                    </Alert>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    </Paper>
                </main>
            </div>
        </>
    );
}