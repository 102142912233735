import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  inline: {
    display: 'inline'
  },
  chipCustom: {
    '& .MuiChip-label': { 
      whiteSpace: 'initial'
    }
  }
}));


 function GridVeiculo(props) {
  
    // const classesGrid = myGridStyle();
    // const classesTaglabel = myTagLabelStyle();
    const classes = useStyles();

    const colo = {backgroundColor: 'green', color: 'white'};
  return (
    <>
    <div style={{display: (props.display ? '' : 'none')}}>
      <List className={classes.root}>      
        {props.veiculos.map((veiculo, index) => {
          return ( 
            <React.Fragment key={veiculo}>
              <Link to={"veiculos/view/" + veiculo.Guid} >
                <ListItem alignItems="flex-start" >
                  <ListItemAvatar>
                  <Icon>directions_car</Icon>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<React.Fragment>
                      <Typography
                          component="span"
                          className={classes.titulo}
                          color="textPrimary"
                        >
                      {veiculo.Placa}
                      </Typography>
                      </React.Fragment>}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {veiculo.NumeroRenavam}
                        </Typography>&nbsp;<br />
                        { veiculo.Chips.map((chip) => {
                                  return (<><Chip label={chip.Label} className={classes.chipCustom} style={{backgroundColor : chip.Style.backgroundColor, color : chip.Style.color, height: '100%', padding: 5}} />&nbsp;</>);
                                }) }
                      </React.Fragment>
                    }
                  />
                </ListItem>
                </Link>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </>
    );
}
export default GridVeiculo;